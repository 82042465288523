import {
  CircularProgress,
  Dialog,
  DialogActions, DialogContent,
  DialogTitle, Divider,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InventoryIcon from '@mui/icons-material/Inventory';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Box from '@mui/material/Box';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import CustomTablePagination from '../components/pagination/pagination-component';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import usuarioService from '../Services/Usuario/usuario-service';
import NovaSolicitacaoReversa from './components/nova-solicitacao-reversa';

import FilesExportFilterEstoque from '../servicos/components/filesExportFilterServicos';

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  item: {
    marginBottom: 30,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}));

export default function EstoqueSolicitacaReversaPage({ microSigaID, handleCloseReversaGestorTecnico, nomeTecnico, idTecnico }) {
  const classes = useStyles();
  const [solicitacaoReversa, setSolicitacaoReversa] = useState([]);
  const [mostrarDetalhes, setMostrarDetalhes] = useState(false);
  const [openModalDetalhesReversa, setOpenModalDetalhesReversa] = useState(false);
  const [reversaSelecionada, setReversaSelecionada] = useState(null);
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [tableKey, setTableKey] = useState(0);
  const [dadosItensSeriesReversa, setdadosItensSeriesReversa] = useState([]);
  const [modalDetalhesItensSeriesReversa, setModalDetalhesItensSeriesReversa] = useState(false);
  const [, setUsuarioEstoqueAtivo] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(mobile ? 10 : 8);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermReversa, setSearchTermReversa] = useState('');
  const [ProdutosFiltrados, setProdutosFiltrados] = useState(reversaSelecionada?.solicitacaoReversaEstoques || []);
  const [filtrarDadosReversa, setFiltrarDadosReversa] = useState(dadosItensSeriesReversa);
  const [carregandoNFReversaId, setCarregandoNFReversaId] = useState(null);
  const [pageReversa, setPageReversa] = useState(0);
  const [rowsPerPageReversa, setRowsPerPageReversa] = useState(8);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [numeroSolicitacao, setNumeroSolicitacao] = useState('');
  const [pesquisa, setPesquisa] = useState('');
  const [carregandoBotaoPesquisar, setCarregandoBotaoPesquisar] = useState(false);
  const [carregandoBotaoLimpar, setCarregandoBotaoLimpar] = useState(false);

  const [dadosExportacao, setDadosExportacao] = useState([]);
  const [temPermissao, setTemPermissao] = useState(true);

  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => 
        permissao.permissoesLista?.some(permissaoItem => 
          permissaoItem.trim() === 'cod_solicitacao_reversa'
        )
      );
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
      return;
    }
  
  }, []);
  
  
  const buscarUsuarioEstoqueAtivo = async () => {
    let idUsuario = idTecnico || authService.getUserInfo().usuario.id;
    const response = await usuarioService.usuarioEstoqueAtivo(idUsuario);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  const novaSolicitaoReversa = () => {
    setMostrarDetalhes(!mostrarDetalhes);
  };

  const fecharTelaNovaReversa = () => {
    setMostrarDetalhes(false);
  };

  const abrirModalDetalhesReversa = (rowData) => {
    setOpenModalDetalhesReversa(true)
    setReversaSelecionada(rowData)
  }

  const fecharModalDetalhesContestacao = () => {
    setSearchTerm('');
    setPage(0);
    setProdutosFiltrados(reversaSelecionada?.solicitacaoReversaEstoques || []);
    setOpenModalDetalhesReversa(false);
  }

  const fecharModalDetalhesItensSeriesReversa = () => {
    setPage(0);
    setSearchTerm('');
    setModalDetalhesItensSeriesReversa(false)
  }

  const filtrarDados = async () => {
    if (!dataInicial || !dataFinal) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, selecione as datas de início e fim antes de pesquisar.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data final.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(dataInicial).isAfter(moment())) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data atual.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setCarregandoBotaoPesquisar(true);
    setPage(0);
    await buscarDadosSolicitacaoReversa(dataInicial, dataFinal, 0, pageSize, pesquisa);
    setCarregandoBotaoPesquisar(false);
  };

  const buscarItemReversa = (term) => {
    if (!term) {
      setProdutosFiltrados(reversaSelecionada?.solicitacaoReversaEstoques || []);
    } else {
      const filtered = reversaSelecionada?.solicitacaoReversaEstoques.filter((item) =>
        item.produtoNome?.toLowerCase().includes(term.toLowerCase()) ||
        item.produtoCodigo?.toLowerCase().includes(term.toLowerCase()) ||
        item.serie?.toLowerCase().includes(term.toLowerCase())
      );
      setProdutosFiltrados(filtered);
    }
  };

  const buscarDadosReversa = (term) => {
    if (!term) {
      setFiltrarDadosReversa(dadosItensSeriesReversa);
    } else {
      const filtered = dadosItensSeriesReversa.filter((item) =>
        item.codProduto.toLowerCase().includes(term.toLowerCase()) ||
        item.descProduto.toLowerCase().includes(term.toLowerCase())
      );
      setFiltrarDadosReversa(filtered);
    }
  };

  const limparPesquisa = () => {
    setPesquisa('');
    buscarDadosSolicitacaoReversa(dataInicial, dataFinal, 0, pageSize, '');
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      setPage(0);
      buscarDadosSolicitacaoReversa(dataInicial, dataFinal, 0, pageSize, nome);
    }, 700), []
  );

  const pesquisarDados = (nome) => {
    if (nome !== pesquisa) {
      setPesquisa(nome);
      setPage(0);
      delayPesquisa(nome);
    }
  };

  const limparDados = async () => {
    setCarregandoBotaoLimpar(true);
    setDataInicial('');
    setDataFinal('');
    await buscarDadosSolicitacaoReversa('', '', page, pageSize, '');
    setCarregandoBotaoLimpar(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChangeReversa = (event, newPage) => {
    setPageReversa(newPage);
  };

  const handleRowsPerPageChangeReversa = (event) => {
    setRowsPerPageReversa(parseInt(event.target.value, 10));
    setPageReversa(0);
  };

  const produtosPaginados = ProdutosFiltrados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const buscarDadosSolicitacaoReversa = async (dataInicial, dataFinal, page, pageSize, searchTerm) => {
    setCarregandoTable(true);

    if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Atenção!', body: 'Data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setDataInicial('');
      setDataFinal('');
      setCarregandoTable(false);
      return;
    }
    const dados = {
      dataInicial: dataInicial ? dataInicial : '',
      dataFinal: dataFinal ? dataFinal : '',
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 8,
      nome: searchTerm ? searchTerm : '',
    };

    let area = authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;

    const estoqueLista = await estoqueService.listarSolicitacoesReversa(dados, microSigaID ? microSigaID : area);

    if (estoqueLista.dados) {
      const dadosAjustados = estoqueLista.dados.map(solicitacao => ({
        ...solicitacao,
        status: solicitacao.status === 'EmAnalise' ? 'Em Análise' :
          solicitacao.status === 'Aprovada' ? 'Aprovada' :
            solicitacao.status === 'Reprovada' ? 'Reprovada' :
              solicitacao.status
      }));

      setSolicitacaoReversa(dadosAjustados);
      setTotalDados(estoqueLista.total);
    }

    setCarregandoTable(false);
  };

  const itensSeriesReversa = async (id, numeroSolicitacao) => {
    try {
      setNumeroSolicitacao(numeroSolicitacao);
      setCarregandoNFReversaId(id);
      let area = authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;
      let dadosItensSeries = await estoqueService.itensSeriesReversa(id, microSigaID ? microSigaID : area);

      if (dadosItensSeries.length > 0) {
        setdadosItensSeriesReversa(dadosItensSeries);
        setFiltrarDadosReversa(dadosItensSeries);
        setModalDetalhesItensSeriesReversa(true);
      } else {
        setMensagemAlert({ title: 'Atenção!', body: 'Nenhum item encontrado para esta NF Reversa.' });
        setTipoAlert('fail');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Atenção!', body: 'Erro ao carregar NF Reversa. Por favor, tente novamente.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    } finally {
      setCarregandoNFReversaId(null);
    }
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    buscarDadosSolicitacaoReversa(dataInicial, dataFinal, newPage, pageSize, searchTerm);
  };

  const mudarTamanhoPagina = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    buscarDadosSolicitacaoReversa(dataInicial, dataFinal, 0, newPageSize, searchTerm);
  };

  const StatusBadge = ({ status }) => {
    let statusText = '';
    let bgColor = '';
    let IconComponent = null;

    switch (status) {
      case 'Aprovada':
        statusText = 'Aprovada';
        bgColor = 'rgba(3, 201, 169, 1)';
        IconComponent = CheckCircleIcon;
        break;
      case 'Em Análise':
        statusText = 'Em Análise';
        bgColor = '#777';
        IconComponent = HourglassEmptyIcon;
        break;
      case 'Reprovada':
        statusText = 'Reprovada';
        bgColor = 'rgba(255, 99, 132, 1)';
        IconComponent = CancelIcon;
        break;
      default:
        statusText = 'Desconhecido';
        bgColor = '#000';
        IconComponent = InfoIcon;
        break;
    }

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0',
        fontSize: '0.9rem',
        fontWeight: '500',
        color: '#fff',
        backgroundColor: bgColor,
        width: '100%',
        textAlign: 'center',
        height: 44,
        marginTop: '10px',
        borderRadius: 15
      }}>
        <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
        {statusText}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await buscarDadosSolicitacaoReversa();
      await buscarUsuarioEstoqueAtivo();
      setTableKey(tableKey + 1);

      if (!mobile) {
        setOpenModalDetalhesReversa(false);
        setModalDetalhesItensSeriesReversa(false);
      }
      setLoading(false);
    };

    fetchData();
  }, [mobile]);
  useEffect(() => {
    carregarDadosExportacao();
  }, [reversaSelecionada]);

  useEffect(() => {
    setProdutosFiltrados(reversaSelecionada?.solicitacaoReversaEstoques || []);
  }, [reversaSelecionada]);

  const carregarDadosExportacao = () => {
    if (reversaSelecionada) {
      const dadosFiltrados = reversaSelecionada.solicitacaoReversaEstoques.map((item) => ({
        Código: item.produtoCodigo || 'Indisponível',
        Nome: item.produtoNome || 'Indisponível',
        Série: item.serie || 'Sem Série',
        Cadastro: item.dataCadastro
          ? moment(item.dataCadastro).format('DD/MM/YYYY')
          : 'Data não disponível',
        Motivo: item.motivo === 'BaseDeTroca' ? 'Base de Troca' : item.motivo || '-',
      }));
  
      setDadosExportacao(dadosFiltrados);
    }
  };
  
  return (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div style={{ margin: 30 }}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {!mostrarDetalhes && (
              <>
                <h3 className={classes.tituloStyle}>Solicitação de Reversa</h3>
                <h4 className={classes.subtituloStyle}>
                  <span
                    onClick={() => {
                      const userInfo = authService.getUserInfo();
                      if (userInfo.usuario.gestor) {
                        window.location.assign('/gestor');
                      } else {
                        window.location.assign('/agenda');
                      }
                    }}
                    className={classes.linkStyle}
                    style={{ cursor: 'pointer' }}
                  >
                    Home
                  </span>
                  {' > '}
                  Solicitação de Reversa
                  {authService.getUserInfo()?.usuario?.gestor === true &&
                    nomeTecnico &&
                    nomeTecnico.trim() !== '' ? (
                    <>
                      {' > '}
                      {nomeTecnico}
                    </>
                  ) : null}
                </h4>
              </>
            )}

            {!mostrarDetalhes ? (temPermissao || authService.getUserInfo()?.usuario?.gestor === true ? (
                <>
                  <div style={{ marginBottom: 20 }}>
                    {authService.getUserInfo()?.usuario?.gestor === true &&
                      nomeTecnico &&
                      nomeTecnico.trim() !== '' ? (
                      <Grid item xs={3} sm={1} md={1} style={{ marginBottom: 20 }}>
                        <Button
                          onClick={handleCloseReversaGestorTecnico}
                          style={{ color: '#209BDE' }}
                          startIcon={<ArrowBackIosIcon />}
                        >
                          Voltar
                        </Button>
                      </Grid>
                    ) : null}

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            id="start-date"
                            label="Data inicial"
                            type="date"
                            value={dataInicial}
                            onChange={(e) => setDataInicial(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            id="end-date"
                            label="Data final"
                            type="date"
                            value={dataFinal}
                            onChange={(e) => setDataFinal(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <BlueButton
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={filtrarDados}
                          fullWidth
                          startIcon={carregandoBotaoPesquisar ? <CircularProgress color="primary" size={20} /> : <SearchIcon />}
                          disabled={carregandoBotaoPesquisar}
                          style={{ height: 55 }}
                        >
                          <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoPesquisar ? ' PESQUISANDO...' : ' PESQUISAR'}</span>
                        </BlueButton>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <BlueButton
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={limparDados}
                          fullWidth
                          startIcon={carregandoBotaoLimpar ? <CircularProgress color="primary" size={20} /> : <ClearIcon />}
                          disabled={carregandoBotaoLimpar}
                          style={{ height: 55 }}
                        >
                          <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoLimpar ? ' LIMPANDO...' : ' LIMPAR'}</span>
                        </BlueButton>
                      </Grid>
                    </Grid>
                  </div>

                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    onClose={(event, reason) => {
                      if (reason !== 'backdropClick') {
                        fecharModalDetalhesContestacao();
                      }
                    }}
                    open={openModalDetalhesReversa}
                    style={{ marginTop: 45, marginBottom: 10 }}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogTitle>
                      <Grid container alignItems="center">
                        <Grid item xs={10} sm={11}>
                          Detalhes da Reversa
                        </Grid>
                        <Grid item xs={2} sm={1}>
                          <IconButton onClick={fecharModalDetalhesContestacao}>
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Box display="flex" flexDirection="column">
                        <Grid item xs={12}>
                          <Paper
                            elevation={3}
                            style={{
                              padding: '10px',
                              borderRadius: '8px',
                              backgroundColor: '#fff',
                              marginBottom: 16,
                            }}
                          >
                            <b style={{ color: '#9b9b9b' }}>
                              Peso: {reversaSelecionada?.peso || '-'} kg | Volume: {reversaSelecionada?.volume || '-'}
                            </b>
                          </Paper>
                        </Grid>
                        <Grid container justifyContent="space-between" style={{ marginBottom: 20 }}>
                        {dadosExportacao && dadosExportacao.length > 0 && (
                          <Grid item xs={mobile ? 12 : 5}>
                            <TextField
                              label="Pesquisar"
                              variant="outlined"
                              value={searchTerm}
                              onChange={(e) => {
                                const term = e.target.value;
                                setSearchTerm(term);
                                buscarItemReversa(term);
                              }}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <>
                                    {searchTerm ? (
                                      <IconButton
                                        onClick={() => {
                                          setSearchTerm('');
                                          buscarItemReversa('');
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    ) : (
                                      <InputAdornment position="end">
                                        <SearchIcon />
                                      </InputAdornment>
                                    )}
                                  </>
                                ),
                              }}
                            />
                          </Grid>
                        )}
                          <Grid item xs={mobile ? 12 : 5} style={{ textAlign: 'right', marginTop: mobile ? 10 : 0 }}>
                            {dadosExportacao && dadosExportacao.length > 0 && (
                              <FilesExportFilterEstoque
                                fileName={`Reversa-${reversaSelecionada?.id || 'Desconhecido'}`}
                                data={dadosExportacao}
                              />
                            )}
                          </Grid>                 
                        </Grid>
                      </Box>
                      {carregandoTable ? (
                        <div className={classes.loadingContainer}>
                          <CircularProgress />
                        </div>
                      ) : produtosPaginados.length === 0 ? (
                        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
                          <Typography variant="subtitle1" color="textSecondary">
                            Nenhum registro encontrado.
                          </Typography>
                        </Grid>
                      ) : mobile ? (
                        <Grid container spacing={1}>
                          {produtosPaginados.map((item, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper style={{ padding: '16px' }}>
                                <Typography component="div" variant="body2">
                                  <strong>Código:</strong> {item.produtoCodigo || '-'}
                                </Typography>
                                <Typography component="div" variant="body2">
                                  <strong>Produto:</strong> {item.produtoNome || '-'}
                                </Typography>
                                <Typography component="div" variant="body2">
                                  <strong>Série:</strong> {item.serie || '-'}
                                </Typography>
                                <Typography component="div" variant="body2">
                                  <strong>Motivo:</strong>{' '}
                                  {item.motivo === 'BaseDeTroca' ? 'Base de Troca' : item.motivo || '-'}
                                </Typography>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Box style={{ flex: 1, overflowY: 'auto' }}>
                          <Grid container>
                            <Grid item xs={2}>
                              <Typography style={{ fontWeight: 'bold' }}>Código</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography style={{ fontWeight: 'bold' }}>Produto</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography style={{ fontWeight: 'bold' }}>Série</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography style={{ fontWeight: 'bold' }}>Motivo</Typography>
                            </Grid>
                          </Grid>
                          {produtosPaginados.map((item, index) => (
                            <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                              <Grid item xs={2}>
                                <Typography component="div" variant="body2">
                                  {item.produtoCodigo || '-'}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography component="div" variant="body2">
                                  {item.produtoNome || '-'}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography component="div" variant="body2">
                                  {item.serie || '-'}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography component="div" variant="body2">
                                  {item.motivo === 'BaseDeTroca' ? 'Base de Troca' : item.motivo || '-'}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      )}
                      <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 15 }}>
                        <Grid item>
                          {produtosPaginados.length > 0 && (
                            <CustomTablePagination
                              count={ProdutosFiltrados.length}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onPageChange={handlePageChange}
                              quantidadeItens={reversaSelecionada?.solicitacaoReversaEstoques?.length || 0}
                              onRowsPerPageChange={handleRowsPerPageChange}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={fecharModalDetalhesContestacao}
                        variant="contained"
                        style={{ float: 'right', width: '40%', height: 55 }}
                      >
                        Fechar
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={modalDetalhesItensSeriesReversa}
                    style={{ marginTop: 45, marginBottom: 10 }}
                    onClose={(event, reason) => {
                      if (reason !== 'backdropClick') {
                        fecharModalDetalhesItensSeriesReversa();
                      }
                    }}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogTitle>
                      <Grid container alignItems="center">
                        <Grid item xs={10} sm={11}>
                          Itens da Reversa
                        </Grid>
                        <Grid item xs={2} sm={1}>
                          <IconButton onClick={fecharModalDetalhesItensSeriesReversa}>
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <Divider />
                    <DialogContent dividers>
                      <Box display="flex" flexDirection="column">
                        <Grid item xs={12}>
                          <Paper
                            elevation={3}
                            style={{
                              padding: '10px',
                              borderRadius: '8px',
                              backgroundColor: '#fff',
                              marginBottom: 16,
                            }}
                          >
                            <b style={{ color: '#9b9b9b' }}>Número da Reversa:</b> {numeroSolicitacao || ''}
                          </Paper>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={mobile ? 12 : 5}>
                            <TextField
                              label="Pesquisar"
                              variant="outlined"
                              fullWidth
                              value={searchTermReversa}
                              onChange={(e) => {
                                const term = e.target.value;
                                setSearchTermReversa(term);
                                buscarDadosReversa(term);
                              }}
                              style={{ marginBottom: '16px' }}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    {searchTermReversa ? (
                                      <IconButton
                                        onClick={() => {
                                          setSearchTermReversa('');
                                          buscarDadosReversa('');
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    ) : (
                                      <InputAdornment position="end">
                                        <SearchIcon />
                                      </InputAdornment>
                                    )}
                                  </>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {carregandoTable ? (
                        <div className={classes.loadingContainer}>
                          <CircularProgress />
                        </div>
                      ) : filtrarDadosReversa.length === 0 ? (
                        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
                          <Typography variant="subtitle1" color="textSecondary">
                            Nenhum registro encontrado.
                          </Typography>
                        </Grid>
                      ) : mobile ? (
                        <Grid container spacing={2}>
                          {filtrarDadosReversa.map((item, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper style={{ padding: '16px', marginBottom: '5px' }}>
                                <Typography component="div" variant="body2">
                                  <strong>Código:</strong> {item.codProduto || '-'}
                                </Typography>
                                <Typography component="div" variant="body2">
                                  <strong>Produto:</strong> {item.descProduto || '-'}
                                </Typography>
                                <Typography component="div" variant="body2">
                                  <strong>Quantidade:</strong> {item.quantidade || '-'}
                                </Typography>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <>
                          <Grid container>
                            <Grid item xs={3}>
                              <Typography style={{ fontWeight: 'bold' }}>Código</Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography style={{ fontWeight: 'bold' }}>Produto</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography style={{ fontWeight: 'bold' }}>Quantidade</Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ margin: '8px 0' }} />
                          <Box style={{ flex: 1, overflowX: 'auto' }}>
                            {filtrarDadosReversa.map((item, index) => (
                              <Box key={index} style={{ marginBottom: '8px' }}>
                                <Grid container style={{ padding: '8px 0' }} wrap="nowrap">
                                  <Grid item xs={3}>
                                    <Typography component="div" variant="body2" noWrap>
                                      {item.codProduto || '-'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography component="div" variant="body2" noWrap>
                                      {item.descProduto || '-'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography component="div" variant="body2" noWrap>
                                      {item.quantidade || '-'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))}
                          </Box>
                        </>
                      )}

                      <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                        <Grid item>
                          {filtrarDadosReversa.length > 0 && (
                            <CustomTablePagination
                              count={filtrarDadosReversa.length}
                              page={pageReversa}
                              rowsPerPage={rowsPerPageReversa}
                              onPageChange={handlePageChangeReversa}
                              quantidadeItens={filtrarDadosReversa.length}
                              onRowsPerPageChange={handleRowsPerPageChangeReversa}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                      <Button
                        onClick={fecharModalDetalhesItensSeriesReversa}
                        variant="contained"
                        style={{ float: 'right', width: '40%', height: 55 }}
                      >
                        Fechar
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Paper className={classes.paper}>
                    <Grid container spacing={1} direction="column">
                      <Grid container justifyContent="space-between" item xs={12} sm={12} style={{ marginBottom: 15 }}>
                        <TextField
                          label="Pesquisar"
                          variant="outlined"
                          value={pesquisa}
                          onChange={(event) => pesquisarDados(event.target.value)}
                          style={{ width: mobile ? '100%' : '40%' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {pesquisa ? (
                                  <IconButton onClick={limparPesquisa}>
                                    <ClearIcon />
                                  </IconButton>
                                ) : (
                                  <SearchIcon />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {authService.getUserInfo()?.usuario?.gestor !== true && (
                          <BlueButton
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={novaSolicitaoReversa}
                            style={{ height: 55, marginTop: mobile ? 10 : 0, width: mobile ? '100%' : '20%' }}
                            startIcon={<Inventory2Icon />}
                          >
                            Nova Solicitação
                          </BlueButton>
                        )}
                      </Grid>
                    </Grid>

                    {carregandoTable ? (
                      <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                        <CircularProgress />
                      </Grid>
                    ) : solicitacaoReversa.length === 0 ? (
                      <Grid container justifyContent="center" alignItems="center" >
                        <Typography variant="subtitle1" color="textSecondary">
                          Não há pedidos de reversas.
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid container spacing={1} alignItems="stretch">
                        {solicitacaoReversa.map((rowData, index) => (
                          <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                            <Paper
                              style={{
                                padding: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                boxSizing: 'border-box',
                              }}
                            >
                              <Box padding={1} style={{ flexGrow: 1 }}>
                                <Typography component="div" variant="body2"><strong>Solicitação: </strong> {rowData.id} </Typography>
                                <Typography component="div" variant="body2"><strong>Data de Cadastro: </strong>{moment(rowData.dataCadastro).format('DD/MM/YYYY')}</Typography>
                              </Box>

                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                  <Typography component="div" variant="body2"><StatusBadge status={rowData.status} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    style={{ height: 55 }}
                                    onClick={() => abrirModalDetalhesReversa(rowData)}
                                    startIcon={<AssignmentIcon />}
                                  >
                                    <span style={{ fontSize: '0.7rem' }}>Detalhes</span>
                                  </BlueButton>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    style={{ height: 55 }}
                                    onClick={() => rowData.notaFiscalReversa && itensSeriesReversa(rowData.notaFiscalReversa.id, rowData.id)}
                                    startIcon={carregandoNFReversaId === rowData.notaFiscalReversa?.id ? <CircularProgress size={20} /> : <InventoryIcon />}
                                    disabled={carregandoNFReversaId === rowData.notaFiscalReversa?.id || rowData.status !== 'Aprovada'}
                                  >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoNFReversaId === rowData.notaFiscalReversa?.id ? 'Carregando...' : 'Reversa'}</span>
                                  </BlueButton>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {solicitacaoReversa.length > 0 && (
                      <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                        <Grid item>
                          <CustomTablePagination
                            count={totalDados}
                            page={page}
                            rowsPerPage={pageSize}
                            onPageChange={mudarPagina}
                            quantidadeItens={totalDados}
                            onRowsPerPageChange={mudarTamanhoPagina}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>

                </>
              ) : (
                <>
                  <Paper className={classes.paper}>
                    <Grid container justifyContent="center">
                      <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                    </Grid>
                  </Paper>
                </>
              )
            ) : (
              <NovaSolicitacaoReversa fecharTelaNovaReversa={fecharTelaNovaReversa} />
            )}
          </>
        )}
      </div>
    </>
  );
}