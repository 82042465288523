import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class NoticiaService {

  async noticias(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/noticias?Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.pesquisa}`, {
        method: 'GET',
        headers: _headerToken,
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async detalheNF(areaAtendimentoID, nfID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/notafiscal/item/${nfID}/${areaAtendimentoID}`, {
        method: 'GET',
        headers: _headerToken,
      });

      if (response.status === 200) {
        return await response.json();
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async listarNotificacoesNaoLidasTecnicos(dados) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/listarNotificacoesNaoLidasTecnicos?TecnicoId=${dados.tecnicoId}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.pesquisa}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });

      if (response.status === 200) {
        return response.json();
      } else {
        const data = await response.json();
        let errorsMessage = null;
        if (data.errors) {
          errorsMessage = Object.values(data.errors).map((error) => error);
        }

        return {
          error: true,
          message: data.erro || errorsMessage,
        };
      }
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }
}

export default new NoticiaService();