import {
  Button,
  Dialog, DialogActions,
  DialogContent, DialogTitle,
  FormControl, FormControlLabel, FormHelperText,
  InputLabel, Paper,
  Radio, RadioGroup, Select,
  TextField
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowBackIos } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import ParticleAnimation from 'react-particle-animation';
import { useHistory } from 'react-router-dom';
import logoOmnilink from '../../src/assets/img/logoOmni.png';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import tecnicoService from '../Services/Tecnico/tecnico-service';
import MaskUtil from '../util/MaskUtil';
import { validateTecnico } from '../util/validateTecnico';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  itemContainer: {
    padding: 16,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const useStylesTable = makeStyles(theme => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Tecnico() {

  const history = useHistory();
  const [loadSave, setLoadSave] = useState(false);
  const [errors, setErrors] = useState({});
  const [tecnico, setTecnico] = useState({
    nome: '',
    cnpj: '',
    cpf: '',
    dataNascimento: '',
    nomeMae: '',
    endereco: {
      logradouro: '',
      numero: '',
      bairro: '',
      municipio: '',
      uf: '',
      cep: '',
      email: '',
      telefone1: '',
      telefone2: '',
    },
    conhecimentoTecnico: '',
    certificadoAnexos: [],
    categoria: [],
    veiculos: [
      {
        categoria: '',
        ano_veiculo: '',
        modelo: '',
        marca: '',
        documentoAnexoVeiculo: []
      }
    ],
    pontoFixoArquivos: [],
    documentoPessoalAnexo: [],
    comprovanteResidenciaAnexo: [],
  });
  const [dadosCNPJ, setDadosCNPJ] = useState({
    endereco: {
      logradouro: null,
      numero: null,
      bairro: null,
      municipio: null,
      uf: null,
      cep: null,
      email: null,
      telefone1: null,
      telefone2: null,
    },
  });
  const [veiculos, setVeiculos] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [novaCategoria, setNovaCategoria] = useState('');
  const [novoAnoVeiculo, setNovoAnoVeiculo] = useState('');
  const [novaMarca, setNovaMarca] = useState('');
  const [novoModelo, setNovoModelo] = useState('');
  const [novoAnexoVeiculo, setNovoAnexoVeiculo] = useState(null);
  const [editCategoria, setEditCategoria] = useState('');
  const [editAnoVeiculo, setEditAnoVeiculo] = useState('');
  const [editMarca, setEditMarca] = useState('');
  const [editModelo, setEditModelo] = useState('');
  const [editAnexoVeiculo, setEditAnexoVeiculo] = useState(null);
  const [abrirModalAddVeiculo, setAbrirModalAddVeiculo] = useState(false);
  const [abrirModalEditVeiculo, setAbrirModalEditVeiculo] = useState(false);
  const inputFileRefs = useRef({});
  const inputFileRefsVeiculos = useRef({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('');
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);

  const handleBack = () => {
    window.location.href = '/'
  };

  const resetarCamposAposDeletarOuAdicionar = () => {
    setNovaCategoria('');
    setNovoAnoVeiculo('');
    setNovaMarca('');
    setNovoModelo('');
    setNovoAnexoVeiculo(null);
  };

  const handleAdicionarVeiculo = () => {
    const anoAtual = new Date().getFullYear();

    if (!novaCategoria || !novoAnoVeiculo || !novaMarca || !novoModelo || !novoAnexoVeiculo) {
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'Preencha todos os campos.' });
      setAlertOpen(true);
      return;
    }

    if (novoAnoVeiculo < 1900 || novoAnoVeiculo > anoAtual + 1) {
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: `Ano do veículo deve estar entre 1900 e ${anoAtual + 1}.` });
      setAlertOpen(true);
      return;
    }

    const novoVeiculo = {
      categoria: novaCategoria,
      ano_veiculo: novoAnoVeiculo,
      marca: novaMarca,
      modelo: novoModelo,
      documentoAnexoVeiculo: novoAnexoVeiculo
    };
    adicionarVeiculo(novoVeiculo);
  };

  const handleEditarVeiculo = () => {
    const anoAtual = new Date().getFullYear();
    const atualizarVeiculos = [...veiculos];
    const index = atualizarVeiculos.findIndex((veiculo) =>
      veiculo.categoria === editCategoria &&
      veiculo.ano_veiculo === editAnoVeiculo &&
      veiculo.marca === editMarca &&
      veiculo.modelo === editModelo &&
      veiculo.documentoAnexoVeiculo === editAnexoVeiculo
    );

    if (!novaCategoria || !novoAnoVeiculo || !novaMarca || !novoModelo || !novoAnexoVeiculo) {
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'Preencha todos os campos.' });
      setAlertOpen(true);
      return;
    }

    if (novoAnoVeiculo < 1900 || novoAnoVeiculo > anoAtual + 1) {
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: `Ano do veículo deve estar entre 1900 e ${anoAtual + 1}.` });
      setAlertOpen(true);
      return;
    }

    atualizarVeiculos[index] = {
      ...atualizarVeiculos[index],
      categoria: novaCategoria,
      ano_veiculo: novoAnoVeiculo,
      marca: novaMarca,
      modelo: novoModelo,
      documentoAnexoVeiculo: novoAnexoVeiculo,
    };

    setVeiculos(atualizarVeiculos);
    setAbrirModalEditVeiculo(false);
  };

  const modalAdicionarVeiculo = () => {
    resetarCamposAposDeletarOuAdicionar();
    setAbrirModalAddVeiculo(true);
  }

  const fecharModalAddVeiculo = () => {
    setAbrirModalAddVeiculo(false);
  }

  const fecharModalEditVeiculo = () => {
    setAbrirModalEditVeiculo(false);
  }

  const modalEditarVeiculo = (rowData) => {
    setEditCategoria(rowData.categoria);
    setEditAnoVeiculo(rowData.ano_veiculo);
    setEditMarca(rowData.marca);
    setEditModelo(rowData.modelo);
    setEditAnexoVeiculo(rowData.documentoAnexoVeiculo);

    setAbrirModalEditVeiculo(true);
  };

  const adicionarVeiculo = (veiculo) => {
    setVeiculos([...veiculos, { categoria: veiculo.categoria, ano_veiculo: veiculo.ano_veiculo, marca: veiculo.marca, modelo: veiculo.modelo, documentoAnexoVeiculo: veiculo.documentoAnexoVeiculo }]);
    setAbrirModalAddVeiculo(false);
  }

  const getMarcasVeiculos = async () => {
    const response = await tecnicoService.getMarcasVeiculos();
    if (response) {
      setMarcas(response);
    }
  }

  const getModelosVeiculos = async (marca) => {
    const response = await tecnicoService.getModelosVeiculos(marca);
    if (response) {
      setModelos(response);
    }
  }

  const limparCertificadoAnexos = async () => {
    setTecnico(t => ({ ...t, certificadoAnexos: [] }));
  }

  const limparVeiculos = async () => {
    setVeiculos([]);
  }

  const limparPontoFixoArquivos = async () => {
    setTecnico(t => ({ ...t, pontoFixoArquivos: [] }));
  }

  useEffect(() => {
    getMarcasVeiculos();
  }, []);

  useEffect(() => {
    if (tecnico.conhecimentoTecnico !== 'true') {
      limparCertificadoAnexos();
    }
  }, [tecnico.conhecimentoTecnico]);

  useEffect(() => {
    if (!tecnico.categoria.includes('Volante')) {
      limparVeiculos();
    }
  }, [tecnico.categoria]);

  useEffect(() => {
    if (!tecnico.categoria.includes('PontoFixo')) {
      limparPontoFixoArquivos();
    }
  }, [tecnico.categoria]);

  const classes = useStyles();
  const classesTable = useStylesTable();

  const categorias = [
    { codigo: 'Volante', descricao: 'Volante' },
    { codigo: 'PontoFixo', descricao: 'Ponto Fixo' },
  ];

  const handleInputChange = (atr, value) => {
    setTecnico(t => ({ ...t, [atr]: value }));
  }

  const cpfChange = (cpf) => {
    setTecnico(t => ({ ...t, cpf: MaskUtil.handleCpfChange(cpf) }));
  }

  const cnpjChange = (cnpj) => {
    setTecnico(t => ({ ...t, cnpj: MaskUtil.handleCnpjChange(cnpj) }));
    if (cnpj.length === 18) {
      verificarCNPJ(cnpj);
    }
  }

  const verificarCNPJ = async (cnpj) => {
    const response = await tecnicoService.consultaCNPJ(cnpj);
    if (response.erro === 'Cnpj inválido' || response.erro === 'O CNPJ informado é inválido') {

      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'O CNPJ informado é inválido.' });
      setAlertOpen(true);

      setDadosCNPJ(t => ({
        ...t,
        endereco: {
          logradouro: '',
          numero: '',
          bairro: '',
          municipio: '',
          uf: '',
          cep: '',
          email: '',
          telefone1: '',
          telefone2: '',
        },
      }));
      setTecnico(t => ({
        ...t,
        endereco: {
          logradouro: '',
          numero: '',
          bairro: '',
          municipio: '',
          uf: '',
          cep: '',
          email: '',
          telefone1: '',
          telefone2: '',
        },
      }));
    }
    else if (response.erro === 'ReceitaWsAPI: Erro TooManyRequests - Too Many Requests') {
      setAlertType('error');
      setAlertMessage({ title: 'Error!', body: 'Erro ao consultar CNPJ. Tente novamente mais tarde.' });
      setAlertOpen(true);
    }
    else {
      setDadosCNPJ(t => ({
        ...t,
        endereco: {
          logradouro: response.logradouro || '',
          numero: response.numero || '',
          bairro: response.bairro || '',
          municipio: response.municipio || '',
          uf: response.uf || '',
          cep: response.cep || '',
          email: response.email || '',
          telefone1: response.telefone1 && response.telefone1.replace(/\D/g, '').length >= 9 
               ? response.telefone1 : '',
          telefone2: response.telefone2 || '',
        },
      }));
      setTecnico(t => ({
        ...t,
        endereco: {
          logradouro: response.logradouro || '',
          numero: response.numero || '',
          bairro: response.bairro || '',
          municipio: response.municipio || '',
          uf: response.uf || '',
          cep: response.cep || '',
          email: response.email || '',
          telefone1: response.telefone1 && response.telefone1.replace(/\D/g, '').length >= 9 
               ? response.telefone1 : '',
          telefone2: response.telefone2 || '',
        },
      }));
    }
  }

  const validarImagem = (tipo) => {
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/jpe', 'image/png', 'image/bmp'];
    return allowedTypes.includes(tipo.toLowerCase());
  };

  const handleAddressChange = (atr, value) => {
    setTecnico(t => ({ ...t, endereco: { ...t.endereco, [atr]: value } }));
  }

  const handleInputFile = (atr, files) => {
    const MAX_SIZE_IMG = 1024 * 1024 * 5;

    if (files && files.length > 0) {
      let anexos = [];
      let arquivoInvalido = false;
      files.forEach(file => {
        if (file?.size > MAX_SIZE_IMG) {
          setAlertType('fail');
          setAlertMessage({ title: 'Atenção!', body: 'O tamanho da imagem não pode ser maior que 5 MB!' });
          setAlertOpen(true);
          if (inputFileRefs.current[atr]) {
            inputFileRefs.current[atr].value = '';
          }
          return;
        }
        if (validarImagem(file.type)) {
          anexos.push(file);
        } else {
          arquivoInvalido = true;
        }
      });
      if (arquivoInvalido) {
        setAlertType('fail');
        setAlertMessage({ title: 'Atenção!', body: 'Tipo de arquivo inválido (PDF, PNG e JPG)' });
        setAlertOpen(true);
        if (inputFileRefs.current[atr]) {
          inputFileRefs.current[atr].value = '';
        }
        return;
      }
      if (anexos.length > 0) {
        setTecnico(t => ({ ...t, [atr]: anexos }));
      }
    }
  }

  const handleInputFileVeiculo = (atr, file) => {
    const MAX_SIZE_IMG = 1024 * 1024 * 5;

    if (file?.size > MAX_SIZE_IMG) {

      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'O tamanho da imagem não pode ser maior que 5 MB!' });
      setAlertOpen(true);
      if (inputFileRefsVeiculos.current[atr]) {
        inputFileRefsVeiculos.current[atr].value = '';
      }
      return;
    }

    if (!validarImagem(file.type)) {
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'Tipo de arquivo inválido(PDF, PNG e JPG)' });
      setAlertOpen(true);
      if (inputFileRefsVeiculos.current[atr]) {
        inputFileRefsVeiculos.current[atr].value = '';
      }
      return;
    } else {
      setNovoAnexoVeiculo(file);
    }
  };

  const getIDMarcaVeiculo = async (marca) => {
    let id = 0;
    marcas?.forEach((m) => {
      if (m.nome === marca) {
        id = m.id;
      }
    });
    return id;
  }

  const getIDModeloVeiculo = async (marcaID, modelo) => {
    let id = 0;

    const modelos = await tecnicoService.getModelosVeiculos(marcaID);
    if (modelos && modelos.length > 0) {
      modelos.forEach((m) => {
        if (m.nome === modelo) {
          id = m.id;
        }
      });
    }
    return id;
  };

  const ajustarMensagemErroFrontEnd = (mensagem) => {
    const errosMapeados = {
      'CNPJ do técnico é inválido': 'O CNPJ informado parece estar incorreto. Por favor, verifique o CNPJ fornecido e tente novamente.',
      'O CPF do técnico é inválido': 'O CPF informado parece estar incorreto. Por favor, verifique o CPF fornecido e tente novamente.',
      'Telefone 1 é inválido': 'Parece que o número do Telefone 1 está incorreto. Por favor, verifique o formato e tente novamente.',
      'Telefone 2 é inválido': 'O número do Telefone 2 parece estar incorreto. Por favor, verifique o formato e tente novamente.',
      'O documento informado já foi cadastrado anteriormente': 'O CPF/CNPJ já está registrado em nosso sistema. Por favor, verifique os dados fornecidos e tente novamente.'
    };

    for (let chave in errosMapeados) {
      if (mensagem.includes(chave)) {
        return errosMapeados[chave];
      }
    }

    return {title: 'Atenção!', body: 'Atenção: Houve um problema com os dados fornecidos. Por favor, verifique os dados fornecidos e tente novamente.' };
  };

  const cadastrarTecnico = async () => {
    const validate = validateTecnico(tecnico);
    if (Object.keys(validate).length !== 0) {
      setErrors(validate);

      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'Preencha os campos obrigatórios.' });
      setAlertOpen(true);
      return {
        success: false,
        message: 'Preencha os campos obrigatórios.',
      };
    }

    if (tecnico.categoria.includes('Volante') && veiculos.length === 0) {
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'Se volante for selecionado, é necessário adicionar ao menos um veículo.' });
      setAlertOpen(true);
      return;
    }

    if(tecnico.nome === tecnico.nomeMae){
      setAlertType('fail');
      setAlertMessage({ title: 'Atenção!', body: 'O nome do técnico não pode igual ao nome da mãe.' });
      setAlertOpen(true);
      return;
    }

    setErrors({});

    const veiculosData = await Promise.all(veiculos.map(async (veiculo) => ({
      categoria: veiculo.categoria,
      ano_veiculo: veiculo.ano_veiculo,
      modelo: await getIDModeloVeiculo(await getIDMarcaVeiculo(veiculo.marca), veiculo.modelo),
      marca: await getIDMarcaVeiculo(veiculo.marca),
      documentoAnexoVeiculo: veiculo.documentoAnexoVeiculo,
    })));

    setTecnico(prevTecnico => ({
      ...prevTecnico,
      veiculos: veiculosData,
    }));

    setErrors({});
    setLoadSave(true);

    let formData = new FormData();
    let formDataVeiculos = new FormData();

    formData.append('Nome', tecnico.nome);
    formData.append('Cnpj', tecnico.cnpj);
    formData.append('Cpf', tecnico.cpf);
    formData.append('DataNascimento', tecnico.dataNascimento);
    formData.append('NomeMae', tecnico.nomeMae);
    formData.append('ConhecimentoTecnico', tecnico.conhecimentoTecnico);
    formData.append('Volante', tecnico.categoria.includes('Volante'));
    formData.append('PontoFixo', tecnico.categoria.includes('PontoFixo'));

    tecnico.certificadoAnexos.forEach((file) => {
      formData.append('CertificadoArquivos', file);
    });

    tecnico.pontoFixoArquivos.forEach((file) => {
      formData.append('PontoFixoArquivos', file);
    });

    tecnico.documentoPessoalAnexo.forEach((file) => {
      formData.append('RgCpfArquivos', file);
    });

    tecnico.comprovanteResidenciaAnexo.forEach((file) => {
      formData.append('ComprovanteEnderecoArquivo', file);
    });

    formData.append('Endereco[Logradouro]', tecnico.endereco.logradouro);
    formData.append('Endereco[Numero]', tecnico.endereco.numero);
    formData.append('Endereco[Bairro]', tecnico.endereco.bairro);
    formData.append('Endereco[Municipio]', tecnico.endereco.municipio);
    formData.append('Endereco[Uf]', tecnico.endereco.uf);
    formData.append('Endereco[Cep]', tecnico.endereco.cep);
    formData.append('Endereco[Email]', tecnico.endereco.email);
    formData.append('Endereco[Telefone1]', tecnico.endereco.telefone1);
    formData.append('Endereco[Telefone2]', tecnico.endereco.telefone2);
    formData.append('Endereco[Logradouro]', tecnico.endereco.logradouro);
    formData.append('Endereco[Numero]', tecnico.endereco.numero);
    formData.append('Endereco[Bairro]', tecnico.endereco.bairro);
    formData.append('Endereco[Municipio]', tecnico.endereco.municipio);
    formData.append('Endereco[Uf]', tecnico.endereco.uf);
    formData.append('Endereco[Cep]', tecnico.endereco.cep);
    formData.append('Endereco[Email]', tecnico.endereco.email);
    formData.append('Endereco[Telefone1]', tecnico.endereco.telefone1);
    formData.append('Endereco[Telefone2]', tecnico.endereco.telefone2);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    const response = await tecnicoService.cadastrarTecnico(formData);

    setLoadSave(false);
    if (response.error) {
      let mensagensSuavizadas;
      if (Array.isArray(response.message)) {
        mensagensSuavizadas = response.message.map(ajustarMensagemErroFrontEnd).join(', ');
      } else {
        mensagensSuavizadas = ajustarMensagemErroFrontEnd(response.message);
      }

      setAlertMessage({ title: 'Erro!', body: mensagensSuavizadas });
      setAlertType('error');
      setAlertOpen(true);
    } else {
      if (tecnico.categoria.includes('Volante') === true) {
        veiculosData.forEach(async (veiculo) => {
          formDataVeiculos.append('TecnicoId', response.id);
          formDataVeiculos.append('Categoria', veiculo.categoria);
          formDataVeiculos.append('Ano', veiculo.ano_veiculo);
          formDataVeiculos.append('ModeloId', veiculo.modelo);
          formDataVeiculos.append('MarcaId', veiculo.marca);
          formDataVeiculos.append('Arquivos', veiculo.documentoAnexoVeiculo);

          // for (var pair of formDataVeiculos.entries()) {
          //   console.log(pair[0] + ', ' + pair[1]);
          // }

          const cadastrarVeiculo = await tecnicoService.cadastrarVeiculoTecnico(formDataVeiculos);

          if (cadastrarVeiculo.error) {
            setAlertMessage({ title: 'Erro!', body: cadastrarVeiculo.message });
            setAlertType('error');
            setAlertOpen(true);
          } else {
            formDataVeiculos = new FormData();
          }
        });
      }
      setAlertType('success');
      setAlertMessage({
        title: 'Sucesso!',
        body: 'Sua solicitação de credenciamento foi enviada com sucesso.',
      });
      setAlertOpen(true);

      setTimeout(() => {
        history.push('/');
      }, 5000);
    }
  };

  const handleOpenConfirmDelete = (index) => {
    setIndexToDelete(index);
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setIndexToDelete(null);
  };

  const handleDeleteVeiculo = (index) => {
    const updatedVeiculos = [...veiculos];
    updatedVeiculos.splice(index, 1);
    setVeiculos(updatedVeiculos);
  };

  const handleDeleteConfirmed = () => {
    if (indexToDelete !== null) {
      handleDeleteVeiculo(indexToDelete);
    }
    handleCloseConfirmDelete();
  };

  return (
    <div style={{ margin: 15 }}>
      <ParticleAnimation
        numParticles={110}
        interactive={true}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
        particleRadius={2.5}
        particleSpeed={1}
        background={{ r: 32, g: 155, b: 222, a: 255 }}
        color={{ r: 255, g: 255, b: 255, a: 255 }}
      />

      <Container style={{ backgroundColor: '#ffffff', position: 'relative', borderRadius: 10, marginTop: 75 }} component="main">

        <Grid className={classes.actions} container alignItems="center" justifyContent="flex-start">
          <Grid item xs={3} sm={2} md={1}>
            <Button
              fullWidth
              style={{ color: '#209BDE', marginTop: '10px' }}
              onClick={handleBack}
              startIcon={<ArrowBackIos />}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={abrirModalAddVeiculo}
          style={{ marginTop: 35, marginBottom: 10 }}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              fecharModalAddVeiculo();
            }
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Adicionar Veículo
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={fecharModalAddVeiculo}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: '16px' }}>
              <InputLabel id="categoria_label">Categoria <span style={{ color: 'red' }}> *</span></InputLabel>
              <Select
                native
                label={<span>Categoria <span style={{ color: 'red' }}> *</span></span>}
                value={novaCategoria}
                onChange={(event) => setNovaCategoria(event.target.value)}
                inputProps={{
                  name: 'categoria',
                  id: 'categoria',
                }}
              >
                <optgroup>
                  <option value="" disabled hidden />
                  <option value="Carro">Carro</option>
                  <option value="Moto">Moto</option>
                </optgroup>
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              required
              fullWidth
              style={{ marginBottom: '16px' }}>
              <TextField
                variant="outlined"
                type="number"
                label={
                  <span>
                    Ano do Veículo <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                value={novoAnoVeiculo}
                onChange={(event) => setNovoAnoVeiculo(event.target.value)}
                fullWidth
                id="anoVeiculo"
                inputRef={el => inputFileRefsVeiculos.current['anoVeiculo'] = el}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth className="limited-select" style={{ marginBottom: '16px' }}>
              <Autocomplete
                id="marcaID"
                options={marcas}
                getOptionLabel={(option) => option.nome}
                value={marcas?.find((option) => option.nome === novaMarca) || null}
                onChange={(event, newValue) => {
                  setNovaMarca(newValue ? newValue.nome : '');
                  const selectedKey = newValue ? newValue.id : null;
                  if (selectedKey) {
                    getModelosVeiculos(selectedKey);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<span>Marca <span style={{ color: 'red' }}> *</span></span>}
                    variant="outlined"
                    fullWidth
                    placeholder="Selecione uma marca"
                    InputLabelProps={{
                      htmlFor: 'marcaID',
                      shrink: true,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      id: 'marcaID',
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth className="limited-select" style={{ marginBottom: '16px' }}>
              <Autocomplete
                disabled={novaMarca == ''}
                id="modeloID"
                options={modelos}
                getOptionLabel={(option) => option.nome}
                value={modelos?.find((option) => option.nome === novoModelo) || null}
                onChange={(event, newValue) => {
                  setNovoModelo(newValue ? newValue.nome : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<span>Modelo <span style={{ color: 'red' }}> *</span></span>}
                    variant="outlined"
                    fullWidth
                    placeholder="Selecione um modelo"
                    InputLabelProps={{
                      htmlFor: 'modeloID',
                      shrink: true,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      id: 'modeloID',
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '16px' }}>
              <TextField
                fullWidth
                label={
                  <>
                    Anexo do Veículo
                    <span style={{ color: 'red' }}> *</span>
                  </>
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  multiple: false
                }}
                variant="outlined"
                type="file"
                onChange={(event) => handleInputFileVeiculo('anexoVeiculo', event.target.files[0])}
                id="anexoVeiculo"
                inputRef={el => inputFileRefsVeiculos.current['anexoVeiculo'] = el}
              />
            </FormControl>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-around' }}>
            <Button
              variant="contained"
              fullWidth
              onClick={fecharModalAddVeiculo}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Cancelar
            </Button>
            <BlueButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAdicionarVeiculo}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Adicionar
            </BlueButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={abrirModalEditVeiculo}
          style={{ marginTop: 35, marginBottom: 10 }}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              fecharModalEditVeiculo();
            }
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Editar Veículo
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={fecharModalEditVeiculo}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
              <InputLabel id="categoria_edit_label">Categoria</InputLabel>
              <Select
                native
                variant="outlined"
                fullWidth
                label={<span>Categoria</span>}
                defaultValue={editCategoria}
                onChange={(event) => setNovaCategoria(event.target.value)}
                inputProps={{
                  name: 'categoria',
                  id: 'categoria',
                }}
              >
                <optgroup>
                  <option value="">Selecione a categoria</option>
                  <option value="Carro">Carro</option>
                  <option value="Moto">Moto</option>
                </optgroup>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
              <TextField
                variant="outlined"
                type="number"
                defaultValue={editAnoVeiculo}
                onChange={(event) => setNovoAnoVeiculo(event.target.value)}
                fullWidth
                label={
                  <span>
                    Ano do Veículo
                  </span>
                }
                id="anoVeiculoEditar"
                inputRef={el => inputFileRefsVeiculos.current['anoVeiculoEditar'] = el}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
              <Autocomplete
                id="marca_edit"
                options={marcas}
                getOptionLabel={(option) => option.nome}
                defaultValue={marcas.find(marca => marca.nome === editMarca) || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setNovaMarca(newValue.nome);
                    getModelosVeiculos(newValue.id);
                  } else {
                    setNovaMarca('');
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Marca" variant="outlined" />}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
              <Autocomplete
                disabled={novaMarca == ''}
                id="modelo_edit"
                options={modelos}
                getOptionLabel={(option) => option.nome}
                defaultValue={modelos.find(modelo => modelo.nome === editModelo) || null}
                onChange={(event, newValue) => {
                  setNovoModelo(newValue ? newValue.nome : '');
                }}
                renderInput={(params) => <TextField {...params} label="Modelo" variant="outlined" />}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '16px' }}>
              <Grid item xs={10} sm={11}>
                Anexo do Veículo
              </Grid>
              {editAnexoVeiculo ? (
                <FormHelperText>Um arquivo já foi anexado.</FormHelperText>
              ) : (
                <FormHelperText>Nenhum arquivo anexado.</FormHelperText>
              )}
              <FormHelperText>Se deseja alterar o anexo, selecione um novo arquivo:</FormHelperText>
              <TextField
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  multiple: false
                }}
                variant="outlined"
                type="file"
                onChange={(event) => handleInputFileVeiculo('anexoVeiculo', event.target.files[0])}
                id="anexoVeiculo"
                inputRef={el => inputFileRefsVeiculos.current['anexoVeiculo'] = el}
              />
            </FormControl>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-around' }}>
            <Button
              variant="contained"
              fullWidth
              onClick={fecharModalEditVeiculo}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Cancelar
            </Button>
            <BlueButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleEditarVeiculo}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Editar
            </BlueButton>
          </DialogActions>
        </Dialog>

        <CssBaseline />
        <div className={classes.paper} style={{ marginTop: '25px' }}>
          <br></br>

          <img style={{ paddingTop: 5 }} src={logoOmnilink}></img>

          <br></br>

          <h2>Credenciamento Técnico</h2>

          <br></br>

          <h3>Dados Pessoais</h3>

          <div className={classesTable.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="nome"
                  label={
                    <>
                      Nome
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  placeholder='Nome'
                  error={!!errors.nome}
                  helperText={errors.nome?.message}
                  value={tecnico.nome}
                  onChange={(event) =>
                    handleInputChange('nome', event.target.value)
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dataNascimento"
                  label={
                    <>
                      Data de Nascimento
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  type="date"
                  error={!!errors.dataNascimento}
                  helperText={errors.dataNascimento?.message}
                  value={tecnico.dataNascimento}
                  onChange={(event) =>
                    handleInputChange('dataNascimento', event.target.value)
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="cnpj"
                  label={
                    <>
                      CNPJ
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  error={!!errors.cnpj}
                  placeholder='CNPJ'
                  helperText={errors.cnpj?.message}
                  value={tecnico.cnpj}
                  onChange={(event) =>
                    cnpjChange(MaskUtil.handleCnpjChange(event.target.value))
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="cpf"
                  label={
                    <>
                      CPF
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  placeholder='CPF'
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message}
                  value={tecnico.cpf}
                  onChange={(event) =>
                    cpfChange(MaskUtil.handleCpfChange(event.target.value))
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  helperText={errors.documentoPessoalAnexo?.message}
                  error={!!errors.documentoPessoalAnexo}
                  fullWidth
                  type="file"
                  id="documentoPessoalAnexo"
                  label={
                    <>
                      Anexe o documento Pessoal (RG/CPF)
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  onChange={(event) => handleInputFile('documentoPessoalAnexo', event.target.files)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    multiple: true
                  }}
                  variant="outlined"
                  inputRef={el => inputFileRefs.current['documentoPessoalAnexo'] = el}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="nomeMae"
                  label={
                    <>
                      Nome da Mãe
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  placeholder='Nome da Mãe'
                  error={!!errors.nomeMae}
                  helperText={errors.nomeMae?.message}
                  value={tecnico.nomeMae}
                  onChange={(event) =>
                    handleInputChange('nomeMae', event.target.value)
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>

          <h3>Endereço</h3>

          <div className={classesTable.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  helperText={errors.logradouro?.message}
                  error={!!errors.logradouro}
                  fullWidth
                  id="logradouro"
                  label={
                    <>
                      Logradouro
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  placeholder='Logradouro'
                  disabled={dadosCNPJ.endereco.logradouro !== '' ? true : false}
                  value={dadosCNPJ.endereco.logradouro || tecnico.endereco.logradouro}
                  onChange={(event) => handleAddressChange('logradouro', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.numero !== '' ? true : false}
                  helperText={errors.numero?.message}
                  error={!!errors.numero}
                  fullWidth
                  id="numero"
                  placeholder='Número'
                  label={
                    <>
                      Número
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.numero || tecnico.endereco.numero}
                  onChange={(event) => handleAddressChange('numero', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.bairro !== '' ? true : false}
                  helperText={errors.bairro?.message}
                  error={!!errors.bairro}
                  fullWidth
                  placeholder='Bairro'
                  id="bairro"
                  label={
                    <>
                      Bairro
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.bairro || tecnico.endereco.bairro}
                  onChange={(event) => handleAddressChange('bairro', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.municipio !== '' ? true : false}
                  helperText={errors.municipio?.message}
                  error={!!errors.municipio}
                  fullWidth
                  id="municipio"
                  placeholder='Município'
                  label={
                    <>
                      Município
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.municipio || tecnico.endereco.municipio}
                  onChange={(event) => handleAddressChange('municipio', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.uf !== '' ? true : false}
                  helperText={errors.uf?.message}
                  error={!!errors.uf}
                  fullWidth
                  id="uf"
                  placeholder='UF'
                  label={
                    <>
                      UF
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.uf || tecnico.endereco.uf}
                  onChange={(event) => handleAddressChange('uf', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.cep !== '' ? true : false}
                  helperText={errors.cep?.message}
                  error={!!errors.cep}
                  fullWidth
                  id="cep"
                  placeholder='CEP'
                  label={
                    <>
                      CEP
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.cep || tecnico.endereco.cep}
                  onChange={(event) => handleAddressChange('cep', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.email !== '' ? true : false}
                  helperText={errors.email?.message}
                  error={!!errors.email}
                  fullWidth
                  id="email"
                  placeholder='E-mail'
                  label={
                    <>
                      E-mail
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.email || tecnico.endereco.email}
                  onChange={(event) => handleAddressChange('email', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={dadosCNPJ.endereco.telefone1 !== '' && dadosCNPJ?.endereco?.telefone1?.length >= 9 ? true : false}
                  helperText={errors.telefone1?.message}
                  error={!!errors.telefone1}
                  fullWidth
                  id="telefone"
                  placeholder='Telefone 1'
                  label={
                    <>
                      Telefone 1
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  value={dadosCNPJ.endereco.telefone1 || tecnico.endereco.telefone1}
                  onChange={(event) => handleAddressChange('telefone1', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  helperText={errors.telefone2?.message}
                  error={!!errors.telefone2}
                  fullWidth
                  id="telefone2"
                  placeholder='Telefone 2'
                  label="Telefone 2"
                  defaultValue={dadosCNPJ.endereco.telefone2}
                  onChange={(event) => handleAddressChange('telefone2', event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  helperText={errors.comprovanteResidenciaAnexo?.message}
                  error={!!errors.comprovanteResidenciaAnexo}
                  fullWidth
                  type="file"
                  id="comprovanteResidenciaAnexo"
                  label={
                    <>
                      Anexe o comprovante de residência
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  onChange={(event) => handleInputFile('comprovanteResidenciaAnexo', event.target.files)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    multiple: true
                  }}
                  variant="outlined"
                  inputRef={el => inputFileRefs.current['comprovanteResidenciaAnexo'] = el}
                />
              </Grid>
            </Grid>
          </div>

          <h3>Detalhes do Técnico</h3>

          <div className={classesTable.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  required
                  error={!!errors.categoria}
                >
                  <h3>Modalidade de atendimento <span style={{ color: 'red' }}> *</span></h3>
                  {categorias.map((categoria) => (
                    <FormControlLabel
                      key={categoria.codigo}
                      control={
                        <Checkbox
                          checked={tecnico.categoria.includes(categoria.codigo)}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            setTecnico((prevTecnico) => {
                              let updatedCategorias = [...prevTecnico.categoria];
                              if (isChecked) {
                                updatedCategorias.push(categoria.codigo);
                              } else {
                                updatedCategorias = updatedCategorias.filter((cat) => cat !== categoria.codigo);
                              }
                              return { ...prevTecnico, categoria: updatedCategorias };
                            });
                          }}
                        />
                      }
                      label={categoria.descricao}
                    />
                  ))}
                  <FormHelperText>
                    {errors.categoria?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              {tecnico.categoria.includes('PontoFixo') ? (
                <TextField
                  error={!!errors.pontoFixoArquivos}
                  helperText={errors.pontoFixoArquivos?.message}
                  type="file"
                  fullWidth
                  id="pontoFixoArquivos"
                  style={{ marginBottom: '20px', marginTop: '10px' }}
                  label={
                    <>
                      Insira foto(s) do ponto fixo
                      <span style={{ color: 'red' }}> *</span>
                    </>
                  }
                  onChange={(event) => handleInputFile('pontoFixoArquivos', event.target.files)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    multiple: true
                  }}
                  variant="outlined"
                  inputRef={el => inputFileRefs.current['pontoFixoArquivos'] = el}
                />
              ) : null}
            </Grid>
          </div>

          <div className={classesTable.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.conhecimentoTecnico}
                >
                  <RadioGroup
                    row
                    required
                    aria-label="conhecimentoTecnico"
                    id="conhecimentoTecnico"
                    name="conhecimentoTecnico"
                    defaultValue="top"
                    value={tecnico.conhecimentoTecnico}
                    onChange={(event) => handleInputChange('conhecimentoTecnico', event.target.value)}
                  >
                    <h3 style={{ marginRight: '10px' }}>Possui formação técnica? <span style={{ color: 'red' }}> *</span></h3>
                    <div style={{ alignContent: 'center', display: 'flex' }} >
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                      <FormControlLabel value="false" control={<Radio color="primary" />} label="Não" />
                    </div>
                  </RadioGroup>

                  <FormHelperText>{errors.conhecimentoTecnico?.message}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                {tecnico.conhecimentoTecnico === 'true' ? (
                  <TextField
                    helperText={errors.certificadoAnexos?.message}
                    error={!!errors.certificadoAnexos}
                    fullWidth
                    type="file"
                    id="certificadoAnexos"
                    style={{ marginBottom: '20px', marginTop: '10px' }}
                    label={
                      <>
                        Insira foto(s) do certificado
                        <span style={{ color: 'red' }}> *</span>
                      </>
                    }
                    onChange={(event) => handleInputFile('certificadoAnexos', event.target.files)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      multiple: true
                    }}
                    variant="outlined"
                    inputRef={el => inputFileRefs.current['certificadoAnexos'] = el}
                  />
                ) : null}
              </Grid>

              <br />

              {tecnico.categoria.includes('Volante') ? (
                <Grid container style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6">Veículos</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <BlueButton
                      variant="contained"
                      size="large"
                      style={{ marginBottom: '10px', marginTop: '10px', height: 55 }}
                      onClick={modalAdicionarVeiculo}
                    >
                      ADICIONAR VEÍCULO
                    </BlueButton>
                  </Grid>

                  <Grid container spacing={1} style={{ marginTop: 15 }}>
                    {veiculos.map((veiculo, index) => (
                      <Grid item xs={12} sm={isMobile ? 12 : 4} md={4} key={`${index}`}>
                        <Paper className={classes.itemContainer}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Tooltip title={veiculo.marca} placement="top" arrow>
                                <Typography component="div" variant="body2" className={classes.truncatedText}>
                                  {veiculo.marca}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Typography component="div" variant="body2">Modelo: {veiculo.modelo}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Typography component="div" variant="body2">Ano: {veiculo.ano_veiculo}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Typography component="div" variant="body2">Categoria: {veiculo.categoria}</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.actionButtons}>
                              <BlueButton
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => modalEditarVeiculo(veiculo)}
                              >
                                Editar
                              </BlueButton>
                              <BlueButton
                                variant="contained"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                onClick={() => handleOpenConfirmDelete(index)}
                                size="small"
                              >
                                Deletar
                              </BlueButton>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : null}

              <br />

            </Grid>
          </div>

          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openConfirmDelete}
            className="custom-dialog"
            style={{ marginTop: 35, marginBottom: 10 }}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleCloseConfirmDelete(false);
              }
            }}
          >
            <DialogTitle>
              <Grid container alignItems="center">
                <Grid item xs={10} sm={11}>
                  Deletar Veículo
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton onClick={handleCloseConfirmDelete}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent dividers>
              <DialogContentText>
                Tem certeza que deseja deletar este veículo? Esta ação não pode ser desfeita.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button
                onClick={handleCloseConfirmDelete}
                variant="contained"
                fullWidth
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
              >
                Cancelar
              </Button>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                onClick={handleDeleteConfirmed} >
                Deletar
              </BlueButton>
            </DialogActions>
          </Dialog>

          {loadSave ? (
            <div style={{ width: '20%', margin: 30 }}>
              <FacebookCircularProgress />
            </div>
          ) : (
            <BlueButton
              fullWidth
              variant="contained"
              onClick={() => cadastrarTecnico()}
              className={classes.submit}
              style={{ marginTop: '40px', height: 55 }}
            >
              SOLICITAR CREDENCIAMENTO
            </BlueButton>
          )}
        </div>
      </Container>

      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        type={alertType}
        onClose={() => setAlertOpen(false)}
      />
    </div>
  );
}
