import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    useMediaQuery, useTheme
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import MenuComponent from '../components/menu/menu-component';
import CustomTablePagination from '../components/pagination/pagination-component';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import usuarioService from '../Services/Usuario/usuario-service';

import FilesExportFilterEstoque from '../servicos/components/filesExportFilterEstoque';

const useStyles = makeStyles(() => ({
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '18px',
        textAlign: 'start',
        padding: '0 0px',
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
}));

export default function HistoricoEstoquePage({ microSigaID, handleCloseHistoricoEstoque, nomeTecnico, idTecnico }) {
    const classes = useStyles();
    const [estoqueItens, setEstoque] = useState([]);
    const [, setUsuarioGestor] = useState(false);
    const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [pageSize, setPageSize] = useState(isMobile ? 8 : 8);
    const [totalDados, setTotalDados] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const isDesktop = useMediaQuery('(min-width:430px)');
    const [temPermissao, setTemPermissao] = useState(true);

    useEffect(() => {
      const usuarioInfo = authService.getUserInfo().usuario;
      const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
      
      const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
      const empresa = usuarioInfo.empresa; 
      const tecnico = usuarioInfo.tecnico;
      let temPermissao = true; 
    
      if (empresa || tecnico) {
        
      temPermissao = permissaoAgendaGlobal?.some(permissao =>
        permissao.permissoesLista?.some(permissaoItem =>
          permissaoItem.trim() === 'cod_historico_estoque'
        )
      );
      } else {
        temPermissao = true;
      }
    
      if (!temPermissao) {
        setTemPermissao(false);
      } else {
        setTemPermissao(true);
      }
      if (tecnicoPrestador && !temPermissao) {
        return;
      }
    
    }, []);
    const [dadosExportacao, setDadosExportacao] = useState([]);
    
    const carregarDadosExportacao = async () => {
        const area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      
        const dados = {
          AreaAtendimentoId: area,
          Limite: totalDados, 
          Pagina: 0,
          Pesquisa: pesquisa || '',
        };
      
        try {
          const response = await estoqueService.listarEstoqueConsolidado(dados);
      
          if (response && response.dados) {
            setDadosExportacao(
              response.dados.map((item) => ({
                Nome: item.produtoNome || 'Indisponível',
                Código: item.produtoCodigo || 'Indisponível',
                Disponível: item.quantidadeDisponivel || 0,
                Recusado: item.quantidadeRecusadoTecnico || 0,
                Reversa: item.quantidadeReversa || 0,
                Devolver: item.quantidadeDevolver || 0,
                Reservado: item.quantidadeReservado || 0,
                RecusadoFabrica: item.quantidadeRecusadoFabrica || 0,
              }))
            );
          } else {
            setDadosExportacao([]);
          }
        } catch (error) {
          console.error('Erro ao carregar dados para exportação:', error);
          setDadosExportacao([]);
        }
      };
    const pesquisarDados = (term) => {
        setPesquisa(term);
        setPage(0);
        estoque(0, pageSize, term);
    };

    const buscarUsuarioEstoqueAtivo = async () => {
        let idUsuario = idTecnico || authService.getUserInfo().usuario.id;
        const response = await usuarioService.usuarioEstoqueAtivo(idUsuario);
        if (response.error) {
            return;
        } else {
            setUsuarioEstoqueAtivo(response);
        }
    };
    useEffect(() => {
        carregarDadosExportacao();
      }, [pesquisa, totalDados]);
    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        estoque(newPage, pageSize, searchTerm);
    };

    const mudarTamanhoPagina = (event) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(0);
        estoque(0, newPageSize, searchTerm);
    };

    const estoque = async (currentPage = 0, currentPageSize = 6, currentPesquisa = '') => {
        setCarregandoTable(true);
        let area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

        const dados = {
            AreaAtendimentoId: area,
            Limite: currentPageSize,
            Pagina: currentPage,
            Pesquisa: currentPesquisa || '',
        };

        let estoqueLista = await estoqueService.listarEstoqueConsolidado(dados);

        if (estoqueLista && estoqueLista.dados) {
            let estoqueAgrupado = estoqueLista.dados.reduce((acc, item) => {
                const produtoKey = `${item.produtoNome}`;
                acc[produtoKey] = {
                    produto: item.produtoNome,
                    codProduto: item.produtoCodigo,
                    recusadoTecnico: item.quantidadeRecusadoTecnico,
                    reversa: item.quantidadeReversa,
                    devolver: item.quantidadeDevolver,
                    reservado: item.quantidadeReservado,
                    disponivel: item.quantidadeDisponivel,
                    recusadoFabrica: item.quantidadeRecusadoFabrica,
                };
                return acc;
            }, {});

            setEstoque(Object.values(estoqueAgrupado));
            setTotalDados(estoqueLista.total);
        } else {
            setEstoque([]);
            setTotalDados(0);
        }
        setCarregandoTable(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await estoque(page, pageSize, pesquisa);
            await buscarUsuarioEstoqueAtivo();
            setUsuarioGestor(authService.getUserInfo().usuario.gestor);
            setLoading(false);
        };
        fetchData();
    }, [isMobile, isDesktop]);

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <div style={{ margin: 30 }}>
                <h3 className={classes.tituloStyle}>Histórico de Estoque</h3>
                <h4 className={classes.subtituloStyle}>
                    <span
                        onClick={() => {
                            const userInfo = authService.getUserInfo();
                            if (userInfo.usuario.gestor) {
                                window.location.assign('/gestor');
                            } else {
                                window.location.assign('/agenda');
                            }
                        }}
                        className={classes.linkStyle}
                        style={{ cursor: 'pointer' }}
                    >
                        Home
                    </span>
                    {' > '}
                    Estoque
                    {' > '}
                    Histórico de Estoque
                    {authService.getUserInfo().usuario.gestor === true &&
                        nomeTecnico &&
                        nomeTecnico.trim() !== '' ? (
                        <>
                            {' > '}
                            {nomeTecnico}
                        </>
                    ) : null}
                </h4>

                {authService.getUserInfo().usuario.gestor === true &&
                    nomeTecnico &&
                    nomeTecnico.trim() !== '' ? (
                    <Grid item xs={3} sm={1} md={1}>
                        <Button
                            onClick={handleCloseHistoricoEstoque}
                            style={{ color: '#209BDE' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Voltar
                        </Button>
                    </Grid>
                ) : null}

                {temPermissao ? (
                    <>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={isMobile ? 12 : 5}>
                                <TextField
                                    label="Pesquisar"
                                    variant="outlined"
                                    fullWidth
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const term = e.target.value;
                                        setSearchTerm(term);
                                        pesquisarDados(term);
                                    }}
                                    style={{ marginBottom: '16px' }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {searchTerm ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            setSearchTerm('');
                                                            pesquisarDados('');
                                                        }}
                                                        size="small"
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                ) : (
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Paper className={classes.paper}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={isMobile ? 12 : 10}>
                            </Grid>
                            <Grid
                            item
                            xs={isMobile ? 12 : 2}
                            style={isMobile ? { paddingBottom: 15 } : {}}
                        >
                        {estoqueItens.length > 0 && (
                            <FilesExportFilterEstoque
                                fileName={nomeTecnico ? `Histórico  ${nomeTecnico}` : 'Meu Histórico Completo'}
                                data={dadosExportacao}
                            />
                        )}
                            </Grid>
                        </Grid>
                            {carregandoTable ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '400px' }}>
                                    <CircularProgress />
                                </Grid>
                            ) : estoqueItens.length === 0 ? (
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Não há itens para exibir.
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid container spacing={1}>
                                    {estoqueItens.length > 0 ? (
                                        estoqueItens.map((item, index) => (
                                            <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                                <Paper
                                                    style={{
                                                        padding: '16px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        // minHeight: '225px',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <Box padding={1} style={{ flexGrow: 1 }}>
                                                        <Typography component="div" variant="body2" style={{ marginBottom: 10 }}>{item.produto}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Reservado:</strong> {item.reservado}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Devolver:</strong> {item.devolver}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Reversa:</strong> {item.reversa}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Recusado pelo Técnico:</strong> {item.recusadoTecnico}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Recusado pela Fábrica:</strong> {item.recusadoFabrica}</Typography>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography align="center">Nenhum registro para exibir</Typography>
                                        </Grid>
                                    )}

                                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                        <Grid item>
                                            {totalDados > 0 && (
                                                <CustomTablePagination
                                                    count={totalDados}
                                                    page={page}
                                                    quantidadeItens={totalDados}
                                                    rowsPerPage={pageSize}
                                                    onPageChange={mudarPagina}
                                                    onRowsPerPageChange={mudarTamanhoPagina}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </>
                ) : (
                    <>
                        <Paper className={classes.paper}>
                            <Grid container justifyContent="center">
                                {authService.getUserInfo().usuario.gestor === true && usuarioEstoqueAtivo === false ? (
                                    <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>O usuário não possui o estoque liberado!</h2>
                                ) : (
                                    <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                                )}
                            </Grid>
                        </Paper>
                    </>
                )}
            </div>
        </>
    );
}
