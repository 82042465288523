import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions, DialogContent, DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery, useTheme
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import CustomTablePagination from '../components/pagination/pagination-component';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import usuarioService from '../Services/Usuario/usuario-service';
import FilesExportFilterEstoque from '../servicos/components/filesExportFilterEstoque';

const useStyles = makeStyles(() => ({
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '18px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

export default function EstoquePage({ microSigaID, handleClose, nomeTecnico, idTecnico }) {
  const classes = useStyles();
  const [estoqueItens, setEstoque] = useState([]);
  const [, setUsuarioGestor] = useState(false);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openModalSeriais, setOpenModalSeriais] = useState(false);
  const [carregandoTableSeriais, setCarregandoTableSeriais] = useState(false);
  const [seriais, setSeriais] = useState([]);
  const [totalDadosSerial, setTotalDadosSerial] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSerial, setPageSerial] = useState(0);
  const theme = useTheme();
  const [, setFiltrarProdutos] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageSizeSerial, setPageSizeSerial] = useState(8);
  const [pageSize, setPageSize] = useState(isMobile ? 8 : 8);
  const [totalDados, setTotalDados] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [pesquisaSerial, setPesquisaSerial] = useState('');
  const [codigoProduto, setCodigoProduto] = useState('');
  const isDesktop = useMediaQuery('(min-width:430px)');
  const [carregandoSerialPorProduto, setcarregandoSerialPorProduto] = useState(null);
  const [dadosExportacao, setDadosExportacao] = useState([]);

  const [temPermissao, setTemPermissao] = useState(true);

  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => 
        permissao.permissoesLista?.some(permissaoItem => 
          permissaoItem.trim() === 'cod_estoque'
        )
      );
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
      return;
    }
  
  }, []);

  const mudarTamanhoPaginaSerial = (event) => {
    const newPageSizeSerial = parseInt(event.target.value, 10);
    setPageSizeSerial(newPageSizeSerial);
    setPageSerial(0);
    buscarSeriaisEquipamentos(codigoProduto, 0, newPageSizeSerial, pesquisaSerial);
  };

  const mudarPaginaSerial = (event, newPage) => {
    setPageSerial(newPage);
    buscarSeriaisEquipamentos(codigoProduto, newPage, pageSizeSerial, pesquisaSerial);
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      setPage(0);
      estoque(0, pageSize, nome);
    }, 700), []
  );

  const pesquisarDados = (nome) => {
    if (nome !== pesquisa) {
      setPesquisa(nome);
      delayPesquisa(nome);
    }
  };

  const handleSerialSearch = (term) => {
    setPesquisaSerial(term);
    setPageSerial(0);
    buscarSeriaisEquipamentos(codigoProduto, 0, pageSizeSerial, term);
  };

  const buscarUsuarioEstoqueAtivo = async () => {
    let idUsuario = idTecnico || authService.getUserInfo().usuario.id;
    const response = await usuarioService.usuarioEstoqueAtivo(idUsuario);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  };
  useEffect(() => {
    carregarDadosExportacao();
  }, [pesquisa, totalDados]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await estoque(page, pageSize, pesquisa);
      await buscarUsuarioEstoqueAtivo();
      setUsuarioGestor(authService.getUserInfo().usuario.gestor);
      setLoading(false);
    };
    fetchData();
  }, [isMobile, isDesktop]);

  useEffect(() => {
    setPageSizeSerial(8);
  }, [isMobile]);

  const carregarSeriais = async () => {
    if (!codigoProduto) {
      return;
    }

    setCarregandoTableSeriais(true);

    try {
      const response = await buscarSeriaisEquipamentos(codigoProduto, pageSerial, pageSizeSerial, pesquisaSerial);

      if (response && response.dados) {
        setSeriais(response.dados);
        setFiltrarProdutos(response.dados);
      } else {
        setSeriais([]);
        setFiltrarProdutos([]);
      }
    } catch (error) {
      setSeriais([]);
      setFiltrarProdutos([]);
    } finally {
      setCarregandoTableSeriais(false);
    }
  };
  const carregarDadosExportacao = async () => {
    const area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
  
    const dados = {
      AreaAtendimentoId: area,
      Limite: totalDados, 
      Pagina: 0,
      Pesquisa: pesquisa || '',
    };
  
    try {
      const response = await estoqueService.listarEstoqueConsolidado(dados);
  
      if (response && response.dados) {
        setDadosExportacao(
          response.dados.map((item) => ({
            Nome: item.produtoNome || 'Indisponível',
            Código: item.produtoCodigo || 'Indisponível',
            Disponível: item.quantidadeDisponivel || 0,
          }))
        );
      } else {
        setDadosExportacao([]);
      }
    } catch (error) {
      setDadosExportacao([]);
    }
  };
  
  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    estoque(newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    estoque(0, parseInt(event.target.value, 10), pesquisa);
  };

  const estoque = async (currentPage = 0, currentPageSize = 6, currentPesquisa = '') => {
    setCarregandoTable(true);
    let area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    const dados = {
      AreaAtendimentoId: area,
      Limite: currentPageSize,
      Pagina: currentPage,
      Pesquisa: currentPesquisa || '',
    };

    let estoqueLista = await estoqueService.listarEstoqueConsolidado(dados);

    if (estoqueLista && estoqueLista.dados) {
      let estoqueAgrupado = estoqueLista.dados.reduce((acc, item) => {
        const produtoKey = `${item.produtoNome}`;
        acc[produtoKey] = {
          produto: item.produtoNome,
          codProduto: item.produtoCodigo,
          recusadoTecnico: item.quantidadeRecusadoTecnico,
          reversa: item.quantidadeReversa,
          devolver: item.quantidadeDevolver,
          reservado: item.quantidadeReservado,
          disponivel: item.quantidadeDisponivel,
          recusadoFabrica: item.quantidadeRecusadoFabrica,
        };
        return acc;
      }, {});

      setEstoque(Object.values(estoqueAgrupado));
      setTotalDados(estoqueLista.total);
    } else {
      setEstoque([]);
      setTotalDados(0);
    }
    setCarregandoTable(false);
  };

  const buscarSeriaisEquipamentos = async (produtoCodigo, page, pageSize, pesquisa) => {
    setCarregandoTableSeriais(true);

    let area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    const dados = {
      AreaAtendimentoId: area,
      CodigoProduto: produtoCodigo,
      Limite: pageSize,
      Pesquisa: pesquisa,
      Pagina: page,
    };

    try {
      const response = await estoqueService.listarSeriaisEquipamentoConsolidado(dados);

      if (response && response.dados && Array.isArray(response.dados)) {
        setTotalDadosSerial(response.total || 0);
        setSeriais(response.dados);
      } else {
        setSeriais([]);
      }
    } catch (error) {
      setSeriais([]);
    } finally {
      setCarregandoTableSeriais(false);
    }
  };

  const abrirModalSeriais = async (produtoCodigo) => {
    if (!produtoCodigo) {
      return;
    }
    setCodigoProduto(produtoCodigo);
    setcarregandoSerialPorProduto(produtoCodigo);
    setPageSerial(0);
    setOpenModalSeriais(true);
    await carregarSeriais();
    setcarregandoSerialPorProduto(null);
  };

  const fecharModalSeriais = () => {
    setOpenModalSeriais(false);
    setSeriais([]);
    setCodigoProduto('');
    setPesquisaSerial('');
  };

  useEffect(() => {
    if (openModalSeriais && codigoProduto) {
      buscarSeriaisEquipamentos(codigoProduto, pageSerial, pageSizeSerial, pesquisaSerial);
    }
  }, [openModalSeriais, codigoProduto]);


  const getStatus = (rowData) => {
    if (rowData.quantidadeDevolver >= 1) return 'Devolver';
    if (rowData.quantidadeDisponivel >= 1) return 'Disponível';
    if (rowData.quantidadeReservado >= 1) return 'Reservado';
    if (rowData.quantidadeReversa >= 1) return 'Reversa';
    return 'Status Desconhecido';
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MenuComponent />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Meu Estoque</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => {
              const userInfo = authService.getUserInfo();
              if (userInfo.usuario.gestor) {
                window.location.assign('/gestor');
              } else {
                window.location.assign('/agenda');
              }
            }}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>
          {' > '}
          Estoque
          {' > '}
          Meu Estoque
          {authService.getUserInfo().usuario.gestor === true &&
            nomeTecnico &&
            nomeTecnico.trim() !== '' ? (
            <>
              {' > '}
              {nomeTecnico}
            </>
          ) : null}
        </h4>

        {authService.getUserInfo().usuario.gestor === true &&
          nomeTecnico &&
          nomeTecnico.trim() !== '' ? (
          <Grid item xs={3} sm={1} md={1}>
            <Button
              onClick={handleClose}
              style={{ color: '#209BDE' }}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
          </Grid>
        ) : null}

        {temPermissao && usuarioEstoqueAtivo === true  ? (
          <>
            <Dialog
              aria-labelledby="customized-dialog-title"
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  fecharModalSeriais();
                }
              }}
              open={openModalSeriais}
              style={{ marginTop: 45, marginBottom: 10 }}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>
                <Grid container alignItems="center">
                  <Grid item xs={10} sm={11}>
                    Seriais
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <IconButton onClick={fecharModalSeriais}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent dividers>
                <Box display="flex" flexDirection="column">
                  <Grid container justifyContent="flex-end">

                    <Grid item xs={isMobile ? 12 : 5}>
                      <TextField
                        label="Pesquisar"
                        variant="outlined"
                        fullWidth
                        value={pesquisaSerial}
                        onChange={(e) => {
                          const term = e.target.value;
                          setPesquisaSerial(term);
                          handleSerialSearch(term);
                        }}
                        style={{ marginBottom: '16px' }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {pesquisa ? (
                                <IconButton
                                  onClick={() => {
                                    setPesquisaSerial('');
                                    handleSerialSearch('');
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              ) : (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {carregandoTableSeriais ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                  </Box>
                ) : seriais.length === 0 ? (
                  <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                    Nenhum registro para exibir
                  </Typography>
                ) : (
                  <>
                    {isMobile ? (
                      <Grid container spacing={2}>
                        {seriais.length > 0 ? (
                          seriais.map((serial, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper elevation={1} style={{ padding: '16px', margin: '1px 0', borderRadius: '8px' }}>
                                <Typography component="div" variant="body2">
                                  <strong>Serial:</strong> {serial.produtoSerial}
                                </Typography>
                                <Typography component="div" variant="body2">
                                  <strong>Status:</strong> {getStatus(serial)}
                                </Typography>
                              </Paper>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                              Nenhum registro para exibir
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={7}>
                            <Typography style={{ fontWeight: 'bold' }}>Serial</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography style={{ fontWeight: 'bold' }}>Status</Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ margin: '8px 0' }} />
                        <Box style={{ flex: 1, overflowX: 'auto' }}>
                          {seriais.length > 0 ? (
                            seriais.map((serial, index) => (
                              <Box key={index} style={{ marginBottom: '8px' }}>
                                <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                                  <Grid item xs={7}>
                                    <Typography component="div" variant="body2" noWrap>
                                      {serial.produtoSerial}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography component="div" variant="body2" noWrap>
                                      {getStatus(serial)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))
                          ) : (
                            <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                              Nenhum registro para exibir
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}

                    {seriais.length >= 8 && (
                      <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                        <Grid item>
                          <CustomTablePagination
                            count={totalDadosSerial}
                            page={pageSerial}
                            rowsPerPage={pageSizeSerial}
                            quantidadeItens={totalDadosSerial}
                            onPageChange={mudarPaginaSerial}
                            onRowsPerPageChange={mudarTamanhoPaginaSerial}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  style={{ float: 'right', width: '40%', height: 55 }}
                  onClick={fecharModalSeriais}
                >
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>

            <Box display="flex" flexDirection="column">
              <Grid container justifyContent="flex-end">


                <Grid item xs={isMobile ? 12 : 5}>
                  <TextField
                    label="Pesquisar"
                    variant="outlined"
                    fullWidth
                    value={pesquisa}
                    onChange={(e) => {
                      const term = e.target.value;
                      setPesquisa(term);
                      pesquisarDados(term);
                    }}
                    style={{ marginBottom: '16px' }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {pesquisa ? (
                            <IconButton
                              onClick={() => {
                                setPesquisa('');
                                pesquisarDados('');
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper className={classes.paper}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={isMobile ? 12 : 10}>
                </Grid>
                <Grid
                item
                xs={isMobile ? 12 : 2}
                style={isMobile ? { paddingBottom: 15 } : {}}
              >
              {estoqueItens.length > 0 && (
                <FilesExportFilterEstoque
                fileName={nomeTecnico ? `Estoque ${nomeTecnico}` : 'Meu Estoque Completo'}
                  data={dadosExportacao}
                />
              )}
                </Grid>
              </Grid>

              {carregandoTable ? (
                <div className={classes.loadingContainer}>
                  <CircularProgress />
                </div>
              ) : estoqueItens.length === 0 ? (
                <Grid container justifyContent="center" alignItems="center" >
                  <Typography variant="subtitle1" color="textSecondary">
                    Não há itens para exibir.
                  </Typography>
                </Grid>
              ) : (
                <Grid container spacing={1} alignItems="stretch">
                  {estoqueItens.length > 0 ? (
                    estoqueItens.map((item, index) => (
                      <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                        <Paper
                          style={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            boxSizing: 'border-box',
                          }}
                        >
                          {isDesktop ? (
                            <>
                              <Box padding={1} style={{ flexGrow: 1 }} key={index}>
                                <Typography component="div" variant="body2"><strong>Produto:</strong> {item.produto} </Typography>
                                <Typography component="div" variant="body2"><strong>Codigo:</strong> {item.codProduto}</Typography>
                                <Typography component="div" variant="body2"><strong>Disponível:</strong> {item.disponivel}</Typography>
                              </Box>
                              <Grid container spacing={1} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                  <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    style={{ height: 55 }}
                                    startIcon={
                                      carregandoSerialPorProduto === item.codProduto ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <AssignmentIcon />
                                      )
                                    }
                                    onClick={() => abrirModalSeriais(item.codProduto)}
                                    disabled={carregandoSerialPorProduto === item.codProduto}
                                  >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoSerialPorProduto === item.codProduto ? 'Carregando...' : 'Visualizar Seriais'}</span>
                                  </BlueButton>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Box padding={1} style={{ flexGrow: 1 }}>
                                <Typography component="div" variant="body2"><strong>Produto:</strong> {item.produto} </Typography>
                                <Typography component="div" variant="body2"><strong>Disponível:</strong> {item.disponivel}</Typography>
                              </Box>

                              <Grid container spacing={1} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                  <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    style={{ height: 55 }}
                                    startIcon={
                                      carregandoSerialPorProduto === item.codProduto ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <AssignmentIcon />
                                      )
                                    }
                                    onClick={() => abrirModalSeriais(item.codProduto)}
                                    disabled={carregandoSerialPorProduto === item.codProduto}
                                  >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoSerialPorProduto === item.codProduto ? 'Carregando...' : 'Visualizar Seriais'}</span>
                                  </BlueButton>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Paper>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography align="center">Nenhum registro para exibir</Typography>
                    </Grid>
                  )}

                  <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                    <Grid item>
                      {totalDados > 0 && (
                        <CustomTablePagination
                          count={totalDados}
                          page={page}
                          quantidadeItens={totalDados}
                          rowsPerPage={pageSize}
                          onPageChange={mudarPagina}
                          onRowsPerPageChange={mudarTamanhoPagina}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </>
        ) : (
          <>
            <Paper className={classes.paper}>
              <Grid container justifyContent="center">
                {authService.getUserInfo().usuario.gestor === true && usuarioEstoqueAtivo === false ? (
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>O usuário não possui o estoque liberado!</h2>
                ) : (
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                )}
              </Grid>
            </Paper>
          </>
        )}
      </div>
    </>
  );
}