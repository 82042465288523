import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class EstoqueRecuperadoService {
  async listarEstoqueRecuperado(dados) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoquerecuperado/listarEstoqueRecuperado?NA=${dados.na}&OS=${dados.os}&AreaAtendimentoERP=${dados.areaAtendimentoERP}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.pesquisa}`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async cadastrarEstoqueRecuperado(body) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoquerecuperado`, {
        method: 'POST',
        headers: _headerToken,
        body: body
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };

    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async deletarEstoqueRecuperado(id) {
    const _headerToken = authService.headerAPITecnico();
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/estoquerecuperado/deletar/${id}`, {
        method: 'PATCH',
        headers: _headerToken,
      });
  
      const data = await response.json();
      if (response.status === 200) {
        return { error: false, result: data };
      } else {
        return { error: true, message: data.erro || 'Erro ao deletar estoque.' };
      }
    } catch (error) {
      return { error: true, message: 'Erro ao deletar estoque.' };
    }
  }

  async editarEstoqueRecuperado(id, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoquerecuperado/${id}`,
        {
          method: 'PUT',
          headers: _headerToken,
          body: dados,
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao editar o produto.',
      };
    }
  }
}
export default new EstoqueRecuperadoService();
