import {
    // Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    CircularProgress,
    IconButton
} from '@material-ui/core';
// import moment from 'moment';
import debounce from 'lodash.debounce';
// import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import EstoqueRecuperadoService from '../../Services/EstoqueRecuperado/estoque-recuperado-service';
import authService from '../../Services/auth/auth-service';
import CustomTablePagination from '../../components/pagination/pagination-component';
import Tooltip from '@mui/material/Tooltip';
import BlueButton from '../../components/button/BlueButton';
import { Autocomplete } from '@material-ui/lab';
import CustomAlert from '../../components/alert/CustomAlert';
// import usuarioService from '../../Services/Usuario/usuario-service';
import remanufaturaService from '../../Services/remanufatura/remanufatura-service';

// Icons
import ClearIcon from '@material-ui/icons/Clear';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    addButton: {
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            marginBottom: theme.spacing(2),
            marginLeft: 0,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    enviarButton: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2),
            width: 'auto',
            maxWidth: '200px',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
    },
    itemContainer: {
        padding: 12,
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    truncatedText: {
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'
    },
    dialogContent: {
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
    },

    dialogPaperMobile: {
        width: '90vw',
        maxWidth: '90vw',
    },
    centeredText: {
        textAlign: 'center',
    },
    textField: {
        paddingTop: theme.spacing(2),
    },
    exportButtonContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    exportButton: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    searchFieldContainer: {
        width: '100%',
        height: 'auto',
    },
    searchField: {
        '& .MuiOutlinedInput-root': {
            height: '43px',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiInputBase-input': {
            padding: '8px 0',
            textAlign: 'center',
        },
        '& .MuiInputLabel-root': {
            top: '-6px',
        },
    },
    searchIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function EstoqueRecuperadoComponent({
    os,
    na,
    tipoServicoAgendamento,
    estoqueItens
}) {
    const classes = useStyles();
    const textRef = useRef();
    const theme = useTheme();
    const [estoqueRecuperadoItens, setEstoqueRecuperadoItens] = useState([]);
    const mobile = useMediaQuery(useTheme().breakpoints.down(800));
    const [pesquisa, setPesquisa] = useState('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const areaAtendimentoERP = authService?.getUserInfo()?.usuario?.areaAtendimentoErp;
    const [abrirDialog, setAbrirDialog] = useState(false);
    const [carregandoEnvioProdutos, setCarregandoEnvioProdutos] = useState(false);
    const [carregandoDeleteEstoque, setCarregandoDeleteEstoque] = useState(false);
    const [carregandoEdicaoProdutos, setCarregandoEdicaoProdutos] = useState(false);
    const [produtosRemanufatura, setProdutosRemanufatura] = useState([]);
    const [produtoEstoqueSelecionado, setProdutoEstoqueSelecionado] = useState(null);
    const [produtoRemanufaturaSelecionado, setProdutoRemanufaturaSelecionado] = useState(null);
    const [serialProdutoRemanufatura, setSerialProdutoRemanufatura] = useState('');
    const [motivoProdutoRemanufatura, setMotivoProdutoRemanufatura] = useState('');
    // const [quantidadeProduto, setQuantidadeProduto] = useState(1);
    const [editandoIndex, setEditandoIndex] = useState(null);
    const [erroProdutoRemanufatura, setErroProdutoRemanufatura] = useState(false);
    const [erroSerial, setErroSerial] = useState(false);
    const [erroMotivo, setErroMotivo] = useState(false);
    const [erroProdutoRemanufaturaEditar, setErroProdutoRemanufaturaEditar] = useState(false);
    const [erroSerialEditar, setErroSerialEditar] = useState(false);
    const [erroMotivoEditar, setErroMotivoEditar] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState({ title: '', body: '' });
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [tipoAlert, setTipoAlert] = useState('success');
    // const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
    const [idRegistroEstoqueRecuperado, setIdRegistroEstoqueRecuperado] = useState('');
    const [abrirConfirmacaoModal, setAbrirModalConfirmacao] = useState(false);
    const [abrirModalEdicao, setAbrirModalEdicao] = useState(false);
    const [codigoProdutoEstoqueSelecionadoEditar, setCodigoProdutoEstoqueSelecionadoEditar] = useState('');
    const [nomeProdutoEstoqueSelecionadoEditar, setNomeProdutoEstoqueSelecionadoEditar] = useState('');
    const [codigoProdutoRemanufaturaSelecionadoEditar, setCodigoProdutoRemanufaturaSelecionadoEditar] = useState('');
    const [nomeProdutoRemanufaturaSelecionadoEditar, setNomeProdutoRemanufaturaSelecionadoEditar] = useState('');
    const [serialProdutoRemanufaturaEditar, setSerialProdutoRemanufaturaEditar] = useState('');
    const [motivoProdutoRemanufaturaEditar, setMotivoProdutoRemanufaturaEditar] = useState('');

    // const [itemEstoque, setItemEstoque] = useState(true);

    const motivosDesconto = [
        {
            id: 'UPGRADE',
            label: 'UPGRADE'
        },
        {
            id: 'COMODATO',
            label: 'COMODATO'
        },
        {
            id: 'GARANTIA',
            label: 'GARANTIA'
        }
    ]

    useEffect(() => {
        listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, page, pageSize, pesquisa);
        // buscarUsuarioEstoqueAtivo();
        buscarProdutosRemanufatura();
    }, [os]);

    // const buscarUsuarioEstoqueAtivo = async () => {
    //     try {
    //         const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    //         if (!response.error) {
    //             setUsuarioEstoqueAtivo(response);
    //         }
    //     } catch (error) {
    //         setMensagemAlert('Erro ao verificar o estoque do usuário.');
    //         setTipoAlert('error');
    //         setAbrirAlert(true);
    //     }
    // };

    const buscarProdutosRemanufatura = async () => {
        const response = await remanufaturaService.listarProdutosRemanufaturadosTecnicos();
        if (!response.error) {
            setProdutosRemanufatura(response);
        }
    };

    const listarEstoqueRecuperadoItens = async (na, os, areaAtendimentoERP, pagina, limite, pesquisa) => {
        setCarregandoTable(true);
        const dados = {
            na: na ? na : '',
            os: os ? os : '',
            areaAtendimentoERP: areaAtendimentoERP ? areaAtendimentoERP : '',
            pagina: pagina ? pagina : 0,
            limite: limite ? limite : 8,
            pesquisa: pesquisa ? pesquisa : '',
        }

        const response = await EstoqueRecuperadoService.listarEstoqueRecuperado(dados);

        if (response.dados) {
            setEstoqueRecuperadoItens(response.dados);
            setTotalDados(response.total);
        }
        setCarregandoTable(false);
    }

    const limparPesquisa = () => {
        setPesquisa('');
        listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, 0, pageSize, '');
    };

    const delayPesquisa = useCallback(
        debounce((nome) => {
            setPage(0);
            listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, 0, pageSize, nome);
        }, 700), []
    );

    const valorPesquisa = (nome) => {
        if (nome !== pesquisa) {
            setPesquisa(nome);
            delayPesquisa(nome);
        }
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(0);
        listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, 0, newPageSize, pesquisa);
    };

    const abrirModalAdicionarItens = async () => {
        setAbrirDialog(true);
    }

    const resetarCampos = () => {
        setProdutoEstoqueSelecionado(null);
        setProdutoRemanufaturaSelecionado(null);
        setMotivoProdutoRemanufatura('');
        setSerialProdutoRemanufatura('');
        // setQuantidadeProduto(1);
        setEditandoIndex(null);
        // setItemEstoque(true);
    };

    const abrirModalConfirmacao = async (id) => {
        setIdRegistroEstoqueRecuperado(id);
        setAbrirModalConfirmacao(true);
    };

    const fecharModalConfirmacao = () => {
        setAbrirModalConfirmacao(false);
    };

    const abrirModalEdicaoEstoque = async (data) => {
        setIdRegistroEstoqueRecuperado(data.id);
        // setProdutoEstoqueSelecionadoEditar(data.codigoEquipamento);
        setCodigoProdutoEstoqueSelecionadoEditar(data.codigoEquipamento);
        setNomeProdutoEstoqueSelecionadoEditar(data.nomeEquipamento);
        setCodigoProdutoRemanufaturaSelecionadoEditar(data.codigoEquipamentoBaseTroca);
        setNomeProdutoRemanufaturaSelecionadoEditar(data.nomeEquipamentoBaseTroca);
        setMotivoProdutoRemanufaturaEditar(data.motivoDesconto);
        setSerialProdutoRemanufaturaEditar(data.serialEquipamentoBaseTroca);
        setAbrirModalEdicao(true);
    };

    const fecharModalEdicaoEstoque = () => {
        setAbrirModalEdicao(false);
        resetarCamposEditar();
    };

    const resetarCamposEditar = () => {
        setIdRegistroEstoqueRecuperado('');
        // setProdutoEstoqueSelecionadoEditar('');
        setCodigoProdutoEstoqueSelecionadoEditar('');
        setNomeProdutoEstoqueSelecionadoEditar('');
        setCodigoProdutoRemanufaturaSelecionadoEditar('');
        setNomeProdutoRemanufaturaSelecionadoEditar('');
        setMotivoProdutoRemanufaturaEditar('');
        setSerialProdutoRemanufaturaEditar('');
    };

    const handleSalvarAlteracoes = async () => {
        let hasError = false;

        if (!produtoEstoqueSelecionado) {
            setMensagemAlert({ title: 'Atenção!', body: 'Selecione um produto.' });
            setTipoAlert('fail');
            setAbrirAlert(true);
            hasError = true;
        }

        // if (quantidadeProduto <= 0) {
        //     setMensagemAlert({ title: 'Atenção!', body: 'Quantidade deve ser maior que 0.' });
        //     setTipoAlert('fail');
        //     setAbrirAlert(true);
        //     hasError = true;
        // }

        if (!produtoRemanufaturaSelecionado || !serialProdutoRemanufatura.trim() || !motivoProdutoRemanufatura.trim()) {
            setMensagemAlert({
                title: 'Atenção!',
                body: 'Os campos de produto base de troca, número serial e o motivo são obrigatórios.',
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
            hasError = true;
        }

        if (hasError) return;

        setCarregandoEnvioProdutos(true);

        try {
            const dados = {
                na: na || '',
                os: os || '',
                areaAtendimentoERP: areaAtendimentoERP || '',
                codigoEquipamento: produtoEstoqueSelecionado.produtoNumero.trim(),
                serialEquipamento: '',
                nomeEquipamento: produtoEstoqueSelecionado.nome.trim(),
                codigoEquipamentoBaseTroca: produtoRemanufaturaSelecionado.produtoCodigo,
                nomeEquipamentoBaseTroca: produtoRemanufaturaSelecionado.produtoNome,
                serialEquipamentoBaseTroca: serialProdutoRemanufatura,
                tipoServico: tipoServicoAgendamento,
                motivoDesconto: motivoProdutoRemanufatura,
                status: 'Temporario'
            };

            const response = await EstoqueRecuperadoService.cadastrarEstoqueRecuperado(JSON.stringify(dados));

            if (!response.error) {
                resetarCampos();
                listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, page, pageSize, pesquisa);
                setMensagemAlert({ title: 'Sucesso!', body: 'Produtos enviados com sucesso!' });
                setTipoAlert('success');
                setAbrirAlert(true);
            } else {
                setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar alguns produtos.' });
                setTipoAlert('error');
                setAbrirAlert(true);
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar os produtos.' });
            setTipoAlert('error');
            setAbrirAlert(true);
        } finally {
            setCarregandoEnvioProdutos(false);
            setAbrirDialog(false);
        }
    }

    const handleDeletarEstoque = async () => {
        setCarregandoDeleteEstoque(true);

        try {
            const response = await EstoqueRecuperadoService.deletarEstoqueRecuperado(idRegistroEstoqueRecuperado);

            if (!response.error) {
                resetarCampos();
                fecharModalConfirmacao();
                listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, page, pageSize, pesquisa);
                setMensagemAlert({ title: 'Sucesso!', body: 'Registro deletado com sucesso!' });
                setTipoAlert('success');
                setAbrirAlert(true);
            } else {
                setMensagemAlert({ title: 'Erro!', body: 'Erro ao deletar registro.' });
                setTipoAlert('error');
                setAbrirAlert(true);
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao deletar registro.' });
            setTipoAlert('error');
            setAbrirAlert(true);
        } finally {
            setCarregandoDeleteEstoque(false);
        }

    }

    const handleSalvarEdicao = async () => {
        let hasError = false;

        // if (!codigoProdutoRemanufaturaSelecionadoEditar || !nomeProdutoRemanufaturaSelecionadoEditar) {
        //     setMensagemAlert({ title: 'Atenção!', body: 'Selecione um produto.' });
        //     setTipoAlert('fail');
        //     setAbrirAlert(true);
        //     hasError = true;
        // }

        // if (quantidadeProduto <= 0) {
        //     setMensagemAlert({ title: 'Atenção!', body: 'Quantidade deve ser maior que 0.' });
        //     setTipoAlert('fail');
        //     setAbrirAlert(true);
        //     hasError = true;
        // }

        if (!codigoProdutoRemanufaturaSelecionadoEditar || !nomeProdutoRemanufaturaSelecionadoEditar || !serialProdutoRemanufaturaEditar.trim() || !motivoProdutoRemanufaturaEditar.trim()) {
            setMensagemAlert({
                title: 'Atenção!',
                body: 'Os campos de produto base de troca, número serial e o motivo são obrigatórios.',
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
            hasError = true;
        }

        if (hasError) return;

        setCarregandoEdicaoProdutos(true);

        try {
            const dados = {
                codigoEquipamento: codigoProdutoEstoqueSelecionadoEditar.trim(),
                nomeEquipamento: nomeProdutoEstoqueSelecionadoEditar.trim(),
                codigoEquipamentoBaseTroca: codigoProdutoRemanufaturaSelecionadoEditar,
                nomeEquipamentoBaseTroca: nomeProdutoRemanufaturaSelecionadoEditar,
                serialEquipamentoBaseTroca: serialProdutoRemanufaturaEditar,
                motivoDesconto: motivoProdutoRemanufaturaEditar,
            };

            const response = await EstoqueRecuperadoService.editarEstoqueRecuperado(idRegistroEstoqueRecuperado, JSON.stringify(dados));

            if (!response.error) {
                fecharModalEdicaoEstoque();
                resetarCamposEditar();
                listarEstoqueRecuperadoItens(na, os, areaAtendimentoERP, page, pageSize, pesquisa);
                setMensagemAlert({ title: 'Sucesso!', body: 'Produto editado com sucesso!' });
                setTipoAlert('success');
                setAbrirAlert(true);
            } else {
                setMensagemAlert({ title: 'Erro!', body: 'Erro ao editar produto.' });
                setTipoAlert('error');
                setAbrirAlert(true);
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao editar produto.' });
            setTipoAlert('error');
            setAbrirAlert(true);
        } finally {
            setCarregandoEdicaoProdutos(false);
        }
    }

    return (
        <>
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />

            <Grid item xs={12}>
                <Paper style={{ padding: 10 }}>
                    <Grid container>
                        <Grid item xs={12} sm={3} md={3}>
                            <Tooltip>
                                <span>
                                    <BlueButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        startIcon={<AddBoxIcon />}
                                        onClick={abrirModalAdicionarItens}
                                        style={{ marginBottom: 20 }}
                                    >
                                        Adicionar Produto
                                    </BlueButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={5}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.searchFieldContainer}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Pesquisar"
                                    value={pesquisa}
                                    onChange={(event) => valorPesquisa(event.target.value)}
                                    className={classes.searchField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pesquisa ? (
                                                    <IconButton onClick={limparPesquisa}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                ) : (
                                                    <SearchIcon />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    {carregandoTable ? (
                        <Grid container justifyContent="center" alignItems="center" style={{ height: '400px' }}>
                            <CircularProgress />
                        </Grid>
                    ) : totalDados == 0 ? (
                        <Typography
                            variant="body1"
                            align="center"
                            color="textSecondary"
                            style={{ paddingTop: theme.spacing(8), paddingBottom: theme.spacing(8) }}
                        >
                            Nenhum produto ou serviço adicionado.
                        </Typography>
                    ) : (
                        <Grid container spacing={1} style={{ marginTop: theme.spacing(3) }} ref={textRef}>
                            {estoqueRecuperadoItens.map((rowData, index) => (
                                <Grid item xs={12} sm={mobile ? 12 : 4} md={4} key={`${index.id}`}>
                                    <Paper className={classes.itemContainer}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{marginTop: 5}}>
                                                <Tooltip title={index.motivoDesconto} placement="top" arrow>
                                                    <Typography component="div" variant="body2" className={classes.truncatedText} >
                                                        {rowData.nomeEquipamentoBaseTroca}
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} className={classes.centeredText}>
                                                <Typography component="div" variant="body2">Código: {rowData.codigoEquipamentoBaseTroca}</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.centeredText}>
                                                <Typography component="div" variant="body2">Serial: {rowData.serialEquipamentoBaseTroca}</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.centeredText}>
                                                <Typography component="div" variant="body2">
                                                    Motivo: {rowData.motivoDesconto}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.actionButtons}>
                                                <BlueButton
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<EditIcon />}
                                                    onClick={() => abrirModalEdicaoEstoque(rowData)}
                                                >
                                                    Editar
                                                </BlueButton>
                                                <BlueButton
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<DeleteIcon />}
                                                    onClick={() => abrirModalConfirmacao(rowData.id)}
                                                    disabled={carregandoDeleteEstoque}
                                                >
                                                    Remover
                                                </BlueButton>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                        {totalDados > 0 && (
                            <Grid item>
                                <CustomTablePagination
                                    count={totalDados}
                                    page={page}
                                    rowsPerPage={pageSize}
                                    onPageChange={mudarPagina}
                                    quantidadeItens={totalDados}
                                    onRowsPerPageChange={mudarTamanhoPagina}
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Dialog
                        open={abrirDialog}
                        aria-labelledby="customized-dialog-title"
                        maxWidth={mobile ? false : 'sm'}
                        classes={{ paper: mobile ? classes.dialogPaperMobile : '' }}
                        style={{ marginTop: 35, marginBottom: 10 }}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                setAbrirDialog(false);
                                resetarCampos();
                            }
                        }}
                    >
                        <DialogTitle>
                            <Grid container alignItems="center">
                                <Grid item xs={10} sm={11}>
                                    Adicionar Produto Recuperado
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton
                                        onClick={() => {
                                            resetarCampos();
                                            setAbrirDialog(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent} dividers>
                            <div style={{ marginBottom: '16px' }}>
                                <Autocomplete
                                    id="produto-select"
                                    options={estoqueItens}
                                    getOptionLabel={(option) => `${option.produtoNumero} - ${option.nome}`}
                                    value={produtoEstoqueSelecionado}
                                    disabled={carregandoEnvioProdutos}
                                    onChange={(event, newValue) => {
                                        setProdutoEstoqueSelecionado(newValue);
                                        // setQuantidadeProduto(1);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                <span>
                                                    Selecionar Produto <span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            variant="outlined"
                                        />
                                    )}
                                    fullWidth
                                />
                            </div>
                            {/* <div style={{ marginBottom: '16px' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label={
                                        <>
                                            Quantidade
                                            <span style={{ color: 'red' }}> *</span>
                                        </>
                                    }
                                    type="number"
                                    value={quantidadeProduto}
                                    onChange={(event) => setQuantidadeProduto(event.target.value)}
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            ...(produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true
                                                ? { max: produtoEstoqueSelecionado?.qtdDisponivel }
                                                : { max: 100000 }
                                            )
                                        },
                                        endAdornment: (
                                            <span style={{ marginLeft: 5 }}>
                                                {produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true ? `/${produtoEstoqueSelecionado?.qtdDisponivel}` : ''}
                                            </span>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div> */}
                            <div style={{ marginBottom: '16px' }}>
                                <Typography component="div" variant="body2">
                                    Informar abaixo o modelo e serial do equipamento retirado do veículo do cliente e o motivo.
                                </Typography>

                                <div style={{
                                    display: 'flex',
                                    gap: '16px',
                                    marginTop: '16px',
                                    flexDirection: 'column'
                                }}>

                                    <Autocomplete
                                        id="produto-select"
                                        options={produtosRemanufatura}
                                        getOptionLabel={(option) => `${option.produtoCodigo} - ${option.produtoNome}`}
                                        value={produtoRemanufaturaSelecionado}
                                        disabled={produtoEstoqueSelecionado?.ehServico === true || editandoIndex !== null}
                                        onChange={(event, newValue) => {
                                            setProdutoRemanufaturaSelecionado(newValue);
                                            setErroProdutoRemanufatura(false);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <span>
                                                        Selecionar Produto Remanufaturado<span style={{ color: 'red' }}> *</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                error={erroProdutoRemanufatura}
                                                helperText={erroProdutoRemanufatura ? 'Seleção obrigatória.' : ''}
                                            />
                                        )}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <>
                                                Número Serial <span style={{ color: 'red' }}> *</span>
                                            </>
                                        }
                                        type="text"
                                        disabled={carregandoEnvioProdutos}
                                        value={serialProdutoRemanufatura}
                                        onChange={(event) => {
                                            setSerialProdutoRemanufatura(event.target.value);
                                            setErroSerial(false);
                                        }}
                                        error={erroSerial}
                                        helperText={erroSerial ? 'Campo obrigatório.' : ''}
                                    />
                                    <Autocomplete
                                        id="motivo-select"
                                        options={motivosDesconto}
                                        getOptionLabel={(option) => option?.label ?? ''}
                                        value={motivosDesconto.find((item) => item.id === motivoProdutoRemanufatura) || null}
                                        disabled={carregandoEnvioProdutos}
                                        onChange={(event, newValue) => {
                                            setMotivoProdutoRemanufatura(newValue ? newValue.id : null);
                                            setErroMotivo(false);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <span>
                                                        Selecionar Motivo <span style={{ color: 'red' }}> *</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                error={erroMotivo}
                                                helperText={erroMotivo ? 'Seleção obrigatória.' : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    resetarCampos();
                                    setAbrirDialog(false);
                                }}
                                variant="contained"
                                size="large"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                            >
                                <span style={{ fontSize: '0.7rem' }}>Voltar</span>
                            </Button>
                            <BlueButton
                                onClick={handleSalvarAlteracoes}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                disabled={!produtoEstoqueSelecionado || carregandoEnvioProdutos}
                                startIcon={
                                    carregandoEnvioProdutos ? <CircularProgress size={20} /> : ''
                                }
                            >
                                <span style={{ fontSize: '0.7rem' }}>
                                    {carregandoEnvioProdutos
                                        ? ' Salvando...' : 'Adicionar'}
                                </span>
                            </BlueButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={abrirModalEdicao}
                        aria-labelledby="customized-dialog-title"
                        maxWidth={mobile ? false : 'sm'}
                        classes={{ paper: mobile ? classes.dialogPaperMobile : '' }}
                        style={{ marginTop: 35, marginBottom: 10 }}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                fecharModalEdicaoEstoque();
                            }
                        }}
                    >
                        <DialogTitle>
                            <Grid container alignItems="center">
                                <Grid item xs={10} sm={11}>
                                    Editar Produto Recuperado
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton
                                        onClick={() => {
                                            fecharModalEdicaoEstoque();
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent} dividers>
                            {/* <div style={{ marginBottom: '16px' }}>
                                <Autocomplete
                                    id="produto-select"
                                    options={estoqueItens}
                                    getOptionLabel={(option) => `${option.produtoNumero} - ${option.nome}`}
                                    value={estoqueItens?.find((option) => option.produtoNumero === produtoEstoqueSelecionadoEditar)}
                                    disabled={carregandoEdicaoProdutos}
                                    onChange={(event, newValue) => {
                                        setProdutoEstoqueSelecionadoEditar(newValue);
                                        // setQuantidadeProduto(1);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                <span>
                                                    Selecionar Produto <span style={{ color: 'red' }}> *</span>
                                                </span>
                                            }
                                            variant="outlined"
                                        />
                                    )}
                                    fullWidth
                                />
                            </div> */}
                            {/* <div style={{ marginBottom: '16px' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label={
                                        <>
                                            Quantidade
                                            <span style={{ color: 'red' }}> *</span>
                                        </>
                                    }
                                    type="number"
                                    value={quantidadeProduto}
                                    onChange={(event) => setQuantidadeProduto(event.target.value)}
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            ...(produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true
                                                ? { max: produtoEstoqueSelecionado?.qtdDisponivel }
                                                : { max: 100000 }
                                            )
                                        },
                                        endAdornment: (
                                            <span style={{ marginLeft: 5 }}>
                                                {produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true ? `/${produtoEstoqueSelecionado?.qtdDisponivel}` : ''}
                                            </span>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div> */}
                            <div style={{ marginBottom: '16px' }}>
                                <Typography component="div" variant="body2">
                                    Informar abaixo o modelo e serial do equipamento retirado do veículo do cliente e o motivo.
                                </Typography>

                                <div style={{
                                    display: 'flex',
                                    gap: '16px',
                                    marginTop: '16px',
                                    flexDirection: 'column'
                                }}>

                                    <Autocomplete
                                        id="produto-select"
                                        options={produtosRemanufatura}
                                        getOptionLabel={(option) => `${option.produtoCodigo} - ${option.produtoNome}`}
                                        value={produtosRemanufatura?.find((option) => option.produtoCodigo === codigoProdutoRemanufaturaSelecionadoEditar)}
                                        disabled={carregandoEdicaoProdutos}
                                        onChange={(event, newValue) => {
                                            setCodigoProdutoRemanufaturaSelecionadoEditar(newValue?.produtoCodigo);
                                            setNomeProdutoRemanufaturaSelecionadoEditar(newValue?.produtoNome);
                                            setErroProdutoRemanufaturaEditar(false);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <span>
                                                        Selecionar Produto Remanufaturado<span style={{ color: 'red' }}> *</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                error={erroProdutoRemanufaturaEditar}
                                                helperText={erroProdutoRemanufaturaEditar ? 'Seleção obrigatória.' : ''}
                                            />
                                        )}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <>
                                                Número Serial <span style={{ color: 'red' }}> *</span>
                                            </>
                                        }
                                        type="text"
                                        disabled={carregandoEdicaoProdutos}
                                        value={serialProdutoRemanufaturaEditar}
                                        onChange={(event) => {
                                            setSerialProdutoRemanufaturaEditar(event.target.value);
                                            setErroSerialEditar(false);
                                        }}
                                        error={erroSerialEditar}
                                        helperText={erroSerialEditar ? 'Campo obrigatório.' : ''}
                                    />
                                    <Autocomplete
                                        id="motivo-select"
                                        options={motivosDesconto}
                                        getOptionLabel={(option) => option?.label ?? ''}
                                        value={motivosDesconto.find((item) => item.id === motivoProdutoRemanufaturaEditar) || null}
                                        disabled={carregandoEdicaoProdutos}
                                        onChange={(event, newValue) => {
                                            setMotivoProdutoRemanufaturaEditar(newValue ? newValue.id : null);
                                            setErroMotivoEditar(false);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <span>
                                                        Selecionar Motivo <span style={{ color: 'red' }}> *</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                error={erroMotivoEditar}
                                                helperText={erroMotivoEditar ? 'Seleção obrigatória.' : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    fecharModalEdicaoEstoque();
                                }}
                                variant="contained"
                                size="large"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                            >
                                <span style={{ fontSize: '0.7rem' }}>Voltar</span>
                            </Button>
                            <BlueButton
                                onClick={handleSalvarEdicao}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                disabled={carregandoEdicaoProdutos}
                                startIcon={
                                    carregandoEdicaoProdutos ? <CircularProgress size={20} /> : ''
                                }
                            >
                                <span style={{ fontSize: '0.7rem' }}>
                                    {carregandoEdicaoProdutos
                                        ? ' Editando...' : 'Editar'}
                                </span>
                            </BlueButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        aria-labelledby="customized-dialog-title"
                        open={abrirConfirmacaoModal}
                        style={{ marginTop: 35, marginBottom: 10 }}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                fecharModalConfirmacao();
                            }
                        }}
                    >
                        <DialogTitle>
                            <Grid container alignItems="center">
                                <Grid item xs={10} sm={11}>
                                    Confirmação
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton onClick={fecharModalConfirmacao}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
                                Você tem certeza que deseja remover o registro?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start' }}>
                                <strong>OBS:</strong> A solicitação uma vez enviada não poderá ser cancelada.
                            </Typography>
                        </DialogContent>

                        <DialogActions style={{ justifyContent: 'space-around' }}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={carregandoDeleteEstoque}
                                onClick={fecharModalConfirmacao}
                                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                            >
                                Cancelar
                            </Button>
                            <BlueButton
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={carregandoDeleteEstoque}
                                onClick={handleDeletarEstoque}
                                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                startIcon={
                                    carregandoDeleteEstoque ? <CircularProgress size={20} /> : ''
                                }
                            >
                                {carregandoDeleteEstoque ? 'Removendo...' : 'Remover'}
                            </BlueButton>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </>
    )
}