import { Accordion, AccordionDetails, AccordionSummary, Box,Grid, ListItem, ListItemIcon, ListItemText, Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FileCopy from '@material-ui/icons/FileCopy';
import AirplayIcon from '@mui/icons-material/Airplay';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactsIcon from '@mui/icons-material/Contacts';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import EngineeringIcon from '@mui/icons-material/Engineering';
import InventoryIcon from '@mui/icons-material/Inventory';
import LineWeight from '@mui/icons-material/LineWeight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ShopIcon from '@mui/icons-material/Shop';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TimelineIcon from '@mui/icons-material/Timeline';
import TodayIcon from '@mui/icons-material/Today';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import usuarioService from '../Services/Usuario/usuario-service';
import authService from '../Services/auth/auth-service';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuComponent from '../components/menu/menu-component';
import EventCalendar from './components/event-calendar';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 25px 15px',
    borderRadius: 10,
    width: '100%',
  },
  paperMobile: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 5px 5px',
    borderRadius: 10,
    width: '100%',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  acessoRapidoStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#1C69AD',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'start',
    padding: '10px 5px',
    marginTop: '0px',
    marginBottom: '8px',
  },
  
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '18px',
    textAlign: 'start',
    padding: '0 0px',
  },
  cardAcesso: {
    flexGrow: 0,
    flexBasis: 'calc(20% - 10px)',
    margin: '5px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#D4EDFE',
    textAlign: 'start',
    alignItems: 'center',
    justifyContent: 'start',
    display: 'flex',
    minHeight: '60px',
    borderRadius: '10px',
    padding: '5px',
    color: '#1C69AD',
    fontWeight: 'bold',
  },
  TextoCardAcesso: {
    margin: '0',
    padding: '5px',
    color: '#1C69AD',
    fontWeight: 'bold',
    whiteSpace: 'normal'
  },
  IconeCardAcesso: {
    marginLeft: '10px',
    color: '#1C69AD',
    fontSize: '37px'
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    cardAcesso: {
      flexBasis: 'calc(50% - 10px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    cardAcesso: {
      flexBasis: 'calc(100% - 10px)',
    },
  },
}));

var menuItems = [
  {
    text: 'Clientes',
    icon: <ContactsIcon />,
    url: '/clientes',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Financeiro',
    icon: <AttachMoneyIcon />,
    url: '/financeiro',
    allowedRoles: ['tecnico'],
  },
  {
    text: 'Oportunidade',
    icon: <ShopIcon />,
    url: '/oportunidades',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Venda',
    icon: <ShoppingCartIcon />,
    url: '/venda',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Gestor',
    icon: <TodayIcon />,
    url: '/gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Agenda',
    icon: <DateRangeIcon />,
    url: '/agenda',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Auditoria',
    icon: <ReceiptLongIcon />,
    url: '/auditoria',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Inventário',
    icon: <InventoryIcon />,
    url: '/inventario',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Ordens de Serviço',
    icon: <ListAltIcon />,
    url: '/ordem-de-servico',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Comunicação',
    icon: <AirplayIcon />,
    url: '/comunicacao',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Notas Fiscais',
    icon: <DescriptionIcon />,
    url: '/notas-fiscais',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Meu Estoque',
    icon: <InventoryIcon />,
    url: '/estoque',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Histórico de Estoque',
    icon: <TimelineIcon />,
    url: '/historico-do-estoque',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Solicitação de Reversa',
    icon: <SettingsBackupRestoreIcon />,
    url: '/solicitacao-reversa',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Usuários',
    icon: <ApartmentIcon />,
    url: '/usuarios',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Técnicos',
    icon: <EngineeringIcon />,
    url: '/cadastro-tecnico',
    allowedRoles: ['tecnico', 'revenda'],
  },
  {
    text: 'Notícias',
    icon: <LineWeight />,
    url: '/noticias',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Manuais',
    icon: <FileCopy />,
    url: '/manuais',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Notificações',
    icon: <MarkChatUnreadIcon />,
    url: '/notificacoes',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Liberação do Estoque',
    icon: <LockOpenIcon />,
    url: '/liberacaoEstoqueGestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Configurar Permissões',
    icon: <PostAddTwoToneIcon />,
    url: '/tecnico-permissoes',
    allowedRoles: ['tecnico'],
  },
];

menuItems.sort((a, b) => a.text.localeCompare(b.text));

const AgendaPage = () => {
  const classes = useStyles();
  const [usuarioInfo, setUsuarioInfo] = useState(null);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [, setLoading] = useState(true);
  const [temPermissao, setTemPermissao] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (storedUserInfo) {
        setUsuarioInfo(storedUserInfo.usuario);
      }
      await buscarUsuarioEstoqueAtivo();
      setLoading(false); 
    };
    fetchUserData();
  }, []);
  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => 
        permissao.permissoesLista?.some(permissaoItem => 
          permissaoItem.trim() === 'cod_agenda'
        )
      );
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
      console.log('Técnico secundário sem permissão para configurar.');
    }
  
  }, []);

  const verificarPermissao = () => {
    if (!usuarioInfo) return {}; 
    const permissaoGlobal = usuarioInfo.permissaoUsuario[0]; 
    const tecnico = usuarioInfo.tecnico;
    const empresa = usuarioInfo.empresa;
  
    if (!tecnico && !empresa) {
      return { 
        cod_configurar_permissoes: true, 
        cod_comunicacao: true, 
        cod_financeiro: true, 
        cod_historico_estoque: true, 
        cod_manuais: true, 
        cod_estoque: true, 
        cod_notas_fiscais: true, 
        cod_noticias: true, 
        cod_notificacoes: true, 
        cod_ordens_servico: true, 
        cod_solicitacao_reversa: true ,
        cod_agenda: true,
        cod_tecnicos: true,
      }; 
    }
    const permissoesLista = permissaoGlobal.permissoesLista || [];
    const permissoesLimpa = permissoesLista.map(perm => perm.trim());

    const permissoes = {
      cod_configurar_permissoes: permissoesLimpa.includes('cod_configurar_permissoes'),
      cod_comunicacao: permissoesLimpa.includes('cod_comunicacao'),
      cod_financeiro: permissoesLimpa.includes('cod_financeiro'),
      cod_historico_estoque: permissoesLimpa.includes('cod_historico_estoque'),
      cod_manuais: permissoesLimpa.includes('cod_manuais'),
      cod_estoque: permissoesLimpa.includes('cod_estoque'),
      cod_notas_fiscais: permissoesLimpa.includes('cod_notas_fiscais'),
      cod_noticias: permissoesLimpa.includes('cod_noticias'),
      cod_notificacoes: permissoesLimpa.includes('cod_notificacoes'),
      cod_ordens_servico: permissoesLimpa.includes('cod_ordens_servico'),
      cod_solicitacao_reversa: permissoesLimpa.includes('cod_solicitacao_reversa'),
      cod_agenda: permissoesLimpa.includes('cod_agenda'),
      cod_tecnicos: permissoesLimpa.includes('cod_tecnicos')
    };
  
    return permissoes;
  };
  
  
  


  const filteredMenuItems = usuarioInfo
  ? menuItems
      .filter((item) => {
        const isGestor = usuarioInfo.gestor === true;
        const isRevenda = usuarioInfo.revenda === true;
        const isTecnico = !isGestor && !isRevenda;

        if (item.allowedRoles.includes('gestor') && isGestor) return true;
        if (item.allowedRoles.includes('revenda') && isRevenda) return true;
        if (item.allowedRoles.includes('tecnico') && isTecnico) return true;

        return false;
      })
      .filter((item) => {
        if (!usuarioEstoqueAtivo && ['Meu Estoque', 'Solicitação de Reversa', 'Notas Fiscais', 'Histórico de Estoque'].includes(item.text)) {
          return false;
        }
        return true;
      })
      .filter((item) => {
        const {
          cod_configurar_permissoes,
          cod_comunicacao,
          cod_financeiro,
          cod_historico_estoque,
          cod_manuais,
          cod_estoque,
          cod_notas_fiscais,
          cod_noticias,
          cod_notificacoes,
          cod_ordens_servico,
          cod_solicitacao_reversa,
          cod_tecnicos,
          cod_agenda
        } = verificarPermissao();

        if (item.text === 'Configurar Permissões' && !cod_configurar_permissoes) {
          return false;
        }
        if (item.text === 'Comunicação' && !cod_comunicacao) {
          return false;
        }
        if (item.text === 'Financeiro' && !cod_financeiro) {
          return false; 
        }
        if (item.text === 'Histórico de Estoque' && !cod_historico_estoque) {
          return false; 
        }
        if (item.text === 'Manuais' && !cod_manuais) {
          return false; 
        }
        if (item.text === 'Meu Estoque' && !cod_estoque) {
          return false; 
        }
        if (item.text === 'Notas Fiscais' && !cod_notas_fiscais) {
          return false;
        }
        if (item.text === 'Notícias' && !cod_noticias) {
          return false; 
        }
        if (item.text === 'Notificações' && !cod_notificacoes) {
          return false; 
        }
        if (item.text === 'Ordens de Serviço' && !cod_ordens_servico) {
          return false;
        }
        if (item.text === 'Solicitação de Reversa' && !cod_solicitacao_reversa) {
          return false; 
        }
        if (item.text === 'Técnicos' && !cod_tecnicos) {
          return false; 
        }
        if (item.text === 'Agenda' && !cod_agenda) {
          return false;
        }
        return true; 
      })
  : []; 

  const temPermissaoAtiva = filteredMenuItems.length > 0;

  const avisoPermissao = !temPermissaoAtiva ? (
    <div style={{ padding: '10px', color: '#1E93D3', textAlign: 'center' }}>
      <strong>Aviso:</strong> Você não tem permissão para visualizar nenhum item do menu.
    </div>
  ) : null;


  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  };

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
    setUsuarioInfo(JSON.parse(localStorage.getItem('userInfo')).usuario);
  }, []);

  return (
    <>
      {temPermissao ? (
    <>
      <MenuComponent />
      <div style={{ margin: 30 }}>
        {usuarioInfo?.revenda ? (
          <>
            <h3 className={classes.tituloStyle}>Bem-vindo!</h3>
            <h4 className={classes.subtituloStyle}>Tela Portal do Técnico</h4>
          </>
        ) : (
          <>
            <h3 className={classes.tituloStyle}>Bem-vindo!</h3>
            <h4 className={classes.subtituloStyle}>Tela inicial do Portal do Técnico</h4>
          </>
        )}

        {!usuarioInfo?.revenda && (
          <Paper className={isMobile ? classes.paperMobile : classes.paper}>
            {isMobile ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4 className={classes.acessoRapidoStyle}>Acesso Rápido</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={classes.flexContainer}>
                    
                    {avisoPermissao}
                    {filteredMenuItems.map((item) => (
                      <ListItem
                        button
                        key={item.url}
                        onClick={() => window.location.href = item.url}
                        className={classes.cardAcesso}
                      >
                        <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box className={classes.flexContainer}>
                {avisoPermissao}
                {filteredMenuItems.map((item) => (
                  <ListItem
                    button
                    key={item.url}
                    onClick={() => window.location.href = item.url}
                    className={classes.cardAcesso}
                  >
                    <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                </Box>
            )}
          </Paper>
        )}
        <Paper className={classes.paper}>
          <EventCalendar />
        </Paper>
      </div>
    </>
      ) : (
        <>
        <MenuComponent />
         <div style={{ margin: 30 }}>
           <h3 className={classes.tituloStyle}>Bem-vindo!</h3>
           <h4 className={classes.subtituloStyle}>Tela inicial do Portal do Técnico</h4>

           {!usuarioInfo?.revenda && (
          <Paper className={isMobile ? classes.paperMobile : classes.paper}>
          {isMobile ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4 className={classes.acessoRapidoStyle}>Acesso Rápido</h4>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.flexContainer}>
                  {filteredMenuItems.map((item) => (
                    <ListItem
                      button
                      key={item.url}
                      onClick={() => window.location.href = item.url}
                      className={classes.cardAcesso}
                    >
                      <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Box>
              <h4 className={classes.acessoRapidoStyle}>Acesso Rápido</h4>
              <Box className={classes.flexContainer}>
              {filteredMenuItems.map((item) => (
                <ListItem
                  button
                  key={item.url}
                  onClick={() => window.location.href = item.url}
                  className={classes.cardAcesso}
                >
                  <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
              </Box>
              </Box>
          )}
        </Paper>
        )}

           <Paper className={classes.paper}>
             <Grid container justifyContent="center">
               <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                 Você não tem permissão para acessar a Agenda!
               </h2>
             </Grid>
           </Paper>
         </div>
       </>
     )}
   </>
  );
};

export default AgendaPage;
