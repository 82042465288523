import { CircularProgress, Grid, makeStyles, Paper, TextField, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import NetworkCellIcon from '@material-ui/icons/NetworkCell';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import RoomIcon from '@material-ui/icons/Room';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import comunicacaoServico from '../Services/Comunicacao/comunicacao-servico';
import authService from '../Services/auth/auth-service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
  },
  help: {
    color: '#9e9e9e',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: 80,
    
  },
  boxSerial: {
    textAlign: 'center',
    display: 'block',
    borderRadius: 5,
    background: '#DCDCDC',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pStyle: {
    margin: '5px 0',
    textAlign: 'center', 
  },

  h2Style: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '18px',
    textAlign: 'start',
    padding: '0 0px',
  }
}));

const useStylesStatus = makeStyles(() => ({
  ligado: {
    textAlign: 'center',
    display: 'block',
    borderRadius: 5,
    color: 'white',
    background: '#008000',
    width: '35px',
    height: '35px',
    padding: '5px',
  },
  desligado: {
    textAlign: 'center',
    display: 'block',
    borderRadius: 5,
    color: 'white',
    background: 'red',
    width: '35px',
    height: '35px',
    padding: '5px',
  }
}));

export default function ComunicacaoPage() {
  const statusClasses = useStylesStatus();
  const classes = useStyles();
  const [serial, setSerial] = useState('');
  const [tecnologia, setTecnologia] = useState('');
  const [loading, setLoading] = useState(false);
  const [comunicacaoItens, setComunicacao] = useState({
    id: '',
    ignition: '',
    gps: '',
    gprs: '',
    data: '',
    datasys: '',
  });
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(800));
  const [, SetIdTabela] = useState(0);
  const [rastreamentoIndividual, setRastreamentoIndividual] = useState([]);
  const [temPermissao, setTemPermissao] = useState(true);

  
  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => 
        permissao.permissoesLista?.some(permissaoItem => 
          permissaoItem.trim() === 'cod_comunicacao'
        )
      );
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
      return;
    }
  
  }, []);

  useEffect(() => {
    SetIdTabela(prevKey => prevKey + 1);
  }, [mobile]);

  const opcoes_tecnologias = [
    { value: 'OM', label: 'OMNILINK' },
    { value: 'ST', label: 'SUNTECH' },
    { value: 'QL', label: 'QUECLINK' },
    { value: 'TT', label: 'TELTONIKA' },
    { value: 'PS', label: 'POSITRON' },
    { value: 'CAL', label: 'CALAMP' },
    { value: 'R', label: 'RST' },
  ];
  const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '55px',
    minHeight: '55px',
    borderRadius: '4px',
    borderColor: '#c4c4c4',
    boxShadow: 'none',
    backgroundColor: '#EFF2F8',
    '&:hover': {
      borderColor: '#209BDE',
    },
  }),

    valueContainer: (provided, ) => ({
      ...provided,
      height: '55px',
      padding: '0 6px',
    }),
    input: (provided, ) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided, ) => ({
      ...provided,
      height: '55px',
    }),
    placeholder: (provided, ) => ({
      ...provided,
      color: '#7F7F7F',
    }),
    singleValue: (provided, ) => ({
      ...provided,
      color: '#000',
    }),
  };



  const styleStatus = (status) => {
    if (status === 1 || status === 2) {
      return statusClasses.ligado;
    } else if (status === 0) {
      return statusClasses.desligado;
    }
  };

   const buscarSerial = async () => {
    if (serial && tecnologia) {
      try {
        setLoading(true);
        const tecnologiaValor = tecnologia === 'ST' ? '' : tecnologia;
        const comunicacaoLista = await comunicacaoServico.comunicacaoLastTrack(tecnologiaValor + serial);
        const rastreamentoIndividual = await comunicacaoServico.rastreamentoIndividual(serial);
  
        if (comunicacaoLista.error) {
          throw new Error(comunicacaoLista.message);
        } else {
          setComunicacao(comunicacaoLista);
        }
  
        if (rastreamentoIndividual.error) {
          throw new Error('Equipamento com o id informado não foi encontrado.');
        } else {
          const _rastreamentoIndividual = rastreamentoIndividual.map((m) => ({
            idEquipamento: m.idEquipamento || '',
            idTecnologia: m.idTecnologia || '',
            nomeTecnologia: m.nomeTecnologia || '',
            idModelo: m.idModelo || '',
            nomeModelo: m.nomeModelo || '',
            status: m.status || '',
            descricaoStatus: m.descricaoStatus || '',
            fone: `(${m.ddd}) ${m.fone}` || '',
            operadora: m.operadora || '',
            operadoraNome: m.operadoraNome || '',
            data: m.data || '',
            sumInput: m.sumInput || '',
            sumOutput: m.sumOutput || '',
            total: m.total || '',
          }));
          setRastreamentoIndividual(_rastreamentoIndividual);
        }
      } catch (error) {
        setMensagemAlert({ title: 'Atenção!', body: `Não foi possível localizar o equipamento com o serial ${serial}.` });
        setTipoAlert('fail');
        setAbrirAlert(true);
      } finally {
        setLoading(false);
      }
    } else {
      setMensagemAlert({ title: 'Atenção!', body: 'Digite o serial e selecione a tecnologia para realizar a busca!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    }
  };

  const latestTwoData = useMemo(() => {
    return rastreamentoIndividual
      .sort((a, b) => new Date(b.data) - new Date(a.data))
      .slice(0, 2); 
  }, [rastreamentoIndividual]);
  
  const limparDados = async () => {
    setTecnologia('');
    setSerial('');
    setComunicacao({
      id: '',
      ignition: '',
      gps: '',
      gprs: '',
      data: '',
      dataSys: '',
    });
    setRastreamentoIndividual([]);
  };

  return (
    <>
    {temPermissao ? (
      <>
      <MenuComponent />
        <CustomAlert
          open={abrirAlert}
          message={mensagemAlert}
          type={tipoAlert}
          onClose={() => setAbrirAlert(false)}
        />

          <div style={{ margin: 30 }}>
            <h3 className={classes.tituloStyle}>Comunicação</h3>
            <h4 className={classes.subtituloStyle}>
              <span
                onClick={() => window.location.href = '/revenda'}
                className={classes.linkStyle}
                style={{ cursor: 'pointer' }}
              >
                Home
              </span> {'>'} Comunicação
            </h4>

            <Grid container spacing={1} alignItems="center" style={{ marginTop: '25px' }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Informe o Serial"
                  id="serial"
                  label={<span>Serial <span style={{ color: 'red' }}>*</span></span>}
                  value={serial}
                  onChange={(e) => setSerial(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Select
                  options={opcoes_tecnologias}
                  value={opcoes_tecnologias.find((tec) => tec.value === tecnologia) || null}
                  onChange={(selectedOption) => {
                    setTecnologia(selectedOption ? selectedOption.value : '');
                  }}
                  placeholder="Selecione a Tecnologia"
                  isClearable
                  styles={customStyles} // Aplicando os estilos personalizados
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <BlueButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  onClick={buscarSerial}
                  startIcon={loading ? <CircularProgress size={20} /> : <SearchIcon />}
                  style={{ height: 55 }}
                >
                  Pesquisar
                </BlueButton>
              </Grid>
              <Grid item xs={12} sm={2}>
                <BlueButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  onClick={limparDados}
                  startIcon={<ClearIcon size={20} />}
                  style={{ height: 55 }}
                >
                  Limpar
                </BlueButton>
              </Grid>
            </Grid>

              <Paper className={classes.paper} style={{ marginTop: 30 }}>
                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid container className={classes.equipamento} justifyContent="space-around">
                      <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }}>Informações do equipamento</h2>
                      </Grid>
                      {loading ? (
                        <Grid item xs={12} className={classes.spinnerContainer}>
                          <CircularProgress />
                        </Grid>
                ) : comunicacaoItens.id ? (
                        <>
                          <Grid className={classes.field} item xs={6} sm={4} md={1}>
                            <strong>Serial</strong>
                            <div className={classes.boxSerial}>{comunicacaoItens.id}</div>
                          </Grid>

                          <Grid className={classes.field} item xs={6} sm={4} md={1}>
                            <strong>Ignição</strong>
                            <PowerSettingsNewIcon className={styleStatus(comunicacaoItens.ignition)} />
                          </Grid>

                          <Grid className={classes.field} item xs={6} sm={4} md={1}>
                            <strong>GPS</strong>
                            <RoomIcon className={styleStatus(comunicacaoItens.gps)} />
                          </Grid>

                          <Grid className={classes.field} item xs={6} sm={4} md={1}>
                            <strong>GPRS</strong>
                            <NetworkCellIcon className={styleStatus(comunicacaoItens.gprs)} />
                          </Grid>

                          <Grid className={classes.field} item xs={6} sm={4} md={2}>
                            <strong>Data GPS</strong>
                            <div className={classes.boxSerial}>
                              {comunicacaoItens.data ? moment(comunicacaoItens.data).format('DD/MM/yyyy HH:mm:ss') : ''}
                            </div>
                          </Grid>

                          <Grid className={classes.field} item xs={6} sm={4} md={2}>
                            <strong>Última Comunicação</strong>
                            <div className={classes.boxSerial}>
                              {comunicacaoItens.dataSys ? moment(comunicacaoItens.dataSys).format('DD/MM/yyyy HH:mm:ss') : ''}
                            </div>
                          </Grid>
                        </>
                        ) : (
                        <Grid item xs={12} className={classes.spinnerContainer}>
                          <p style={{ textAlign: 'center', color: '#7F7F7F' }}>
                            Por favor, pesquise um serial para ver as informações do equipamento.
                          </p>
                        </Grid>
                )}
                    </Grid>
                  </Grid>
                </Paper>

                {comunicacaoItens.id && (
                  <Paper className={classes.paper} style={{ marginTop: 30 }}>
                    {loading ? (
                      <div className={classes.spinnerContainer}>
                        <CircularProgress />
                      </div>
                    ) : (
                      latestTwoData.length > 0 && (
                        <div>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <h2 className={classes.h2Style}>Detalhe do Equipamento</h2>
                              <p className={classes.pStyle}><strong>Tecnologia:</strong> {latestTwoData[0].nomeTecnologia}</p>
                              <p className={classes.pStyle}><strong>Modelo:</strong> {latestTwoData[0].nomeModelo}</p>
                              <p className={classes.pStyle}><strong>Status:</strong> {latestTwoData[0].descricaoStatus}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h2 className={classes.h2Style}>Detalhe dos Chips</h2>
                              <Grid container spacing={2}>
                                {latestTwoData.map((item, index) => (
                                  <Grid item xs={12} sm={6} key={index}>
                                    <div>
                                      <p className={classes.pStyle}><strong>Telefone:</strong> {item.fone}</p>
                                      <p className={classes.pStyle}><strong>Operadora:</strong> {item.operadoraNome}</p>
                                      <p className={classes.pStyle}><strong>Data:</strong> {moment(item.data).format('DD/MM/yyyy HH:mm:ss')}</p>
                                    </div>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}
                  </Paper>
                )}
          </div>
        </>
        ) : (
          <>
            <MenuComponent />
            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Comunicação</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => (window.location.href = '/agenda')}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span>{' '}
                {'>'} Comunicação
              </h4>

              <Paper className={classes.paper}>
                <Grid container justifyContent="center">
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                    Você não tem permissão para acessar esta página!
                  </h2>
                </Grid>
              </Paper>
            </div>
          </>
        )
      }
    </>
  );
}
