import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CustomAlert from '../../../components/alert/CustomAlert';
import BlueButton from '../../../components/button/BlueButton';
import authService from '../../../Services/auth/auth-service';
import remanufaturaService from '../../../Services/remanufatura/remanufatura-service';
import usuarioService from '../../../Services/Usuario/usuario-service';
import FilesExportFilterProducts from '../../../servicos/components/filesExportFilterProducts';

const useStyles = makeStyles((theme) => ({
  addButton: {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  enviarButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      width: 'auto',
      maxWidth: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    padding: 12,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(1),
  },
  truncatedText: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
  },

  dialogPaperMobile: {
    width: '90vw',
    maxWidth: '90vw',
  },
  centeredText: {
    textAlign: 'center',
  },
  textField: {
    paddingTop: theme.spacing(2),
  },
  exportButtonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  exportButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchFieldContainer: {
    width: '100%',
    height: 'auto',
  },
  searchField: {
    '& .MuiOutlinedInput-root': {
      height: '43px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-input': {
      padding: '8px 0',
      textAlign: 'center',
    },
    '& .MuiInputLabel-root': {
      top: '-6px',
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function ProdutosTable({
  estoqueItens,
  itensOS,
  addProduto,
  alteraProduto,
  consultaDetalheOS,
  consultaProdutosEstoque,
  os,
  na,
}) {

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
    buscarProdutosRemanufatura();
  }, [os]);

  const classes = useStyles();
  const [produtosTemporarios, setProdutosTemporarios] = useState([]);
  const [produtosRemanufatura, setProdutosRemanufatura] = useState([]);
  const [produtoEstoqueSelecionado, setProdutoEstoqueSelecionado] = useState(null);
  const [produtoRemanufaturaSelecionado, setProdutoRemanufaturaSelecionado] = useState(null);
  const [serialProdutoRemanufatura, setSerialProdutoRemanufatura] = useState('');
  const [motivoProdutoRemanufatura, setMotivoProdutoRemanufatura] = useState('');
  const [quantidadeProduto, setQuantidadeProduto] = useState(1);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [abrirDialog, setAbrirDialog] = useState(false);
  const [editandoIndex, setEditandoIndex] = useState(null);
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [mensagemAlert, setMensagemAlert] = useState({ title: '', body: '' });
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [tipoAlert, setTipoAlert] = useState('success');
  const [dataExportacao, setDataExportacao] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [carregandoEnvioProdutos, setCarregandoEnvioProdutos] = useState(false);
  const textRef = useRef();
  const [itemEditado, setItemEditado] = useState('');
  const [itemEstoque, setItemEstoque] = useState(true);
  const [erroProdutoRemanufatura, setErroProdutoRemanufatura] = useState(false);
  const [erroSerial, setErroSerial] = useState(false);
  const [erroMotivo, setErroMotivo] = useState(false);
  const [baseDeTroca, setBaseDeTroca] = useState(false);
  const temProdutoAprovadoNaoTarifa = itensOS?.concat(produtosTemporarios).some(
    (item) => item.descStatus === 'Aprovado' && item.produto !== 'TARIFA DE DESLOCAMENTO' && item.produto !== 'SERVICO DE PEDAGIO'
  );
  const itemsPerPage = isMobile ? 3 : 6;

  const motivosDesconto = [
    {
      id: 'UPGRADE',
      label: 'UPGRADE'
    },
    {
      id: 'COMODATO',
      label: 'COMODATO'
    },
    {
      id: 'GARANTIA',
      label: 'GARANTIA'
    }
  ]

  const abrirModalAdicionarItens = async () => {
    setProdutoRemanufaturaSelecionado(null);
    setSerialProdutoRemanufatura('');
    setBaseDeTroca(false);
    setAbrirDialog(true);
  }

  const buscarProdutosRemanufatura = async () => {
    const response = await remanufaturaService.listarProdutosRemanufaturadosTecnicos();
    if (!response.error) {
      setProdutosRemanufatura(response);
    }
  };

  const buscarUsuarioEstoqueAtivo = async () => {
    try {
      const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
      if (!response.error) {
        setUsuarioEstoqueAtivo(response);
      }
    } catch (error) {
      setMensagemAlert('Erro ao verificar o estoque do usuário.');
      setTipoAlert('error');
      setAbrirAlert(true);
    }
  };

  const adicionarProdutoTemporario = () => {
    try {
      if (!produtoEstoqueSelecionado || quantidadeProduto < 1) {
        setMensagemAlert('Por favor, selecione um produto e defina uma quantidade válida.');
        setAbrirAlert(true);
        return;
      }

      const produtosExistentes = produtosTemporarios.filter(
        produto => produto.produtoId === produtoEstoqueSelecionado.produtoId
      );

      const produtosInseridos = itensOS.filter((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

      let quantidadeTotal = parseFloat(quantidadeProduto);

      if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === true) {
        if (produtosInseridos) {
          produtosInseridos.forEach(produto => {
            quantidadeTotal += parseFloat(produto.quantidade);
          });
        } else if (produtosExistentes.length > 0) {
          produtosExistentes.forEach(produto => {
            quantidadeTotal += parseFloat(produto.quantidade);
          });
        }

        if (quantidadeTotal > produtoEstoqueSelecionado.qtdDisponivel) {
          setMensagemAlert({
            title: 'Atenção!',
            body: 'A soma total dos itens adicionados excede a quantidade do produto disponível em estoque.',
          });
          setTipoAlert('fail');
          setAbrirAlert(true);
          return;
        }
      } else if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === false) {
        if (produtosInseridos) {
          produtosInseridos.forEach(produto => {
            quantidadeTotal += parseFloat(produto.quantidade);
          });
        } else if (produtosExistentes.length > 0) {
          produtosExistentes.forEach(produto => {
            quantidadeTotal += parseFloat(produto.quantidade);
          });
        }

        if (quantidadeTotal > produtoEstoqueSelecionado.limiteQuantidade) {
          setMensagemAlert({
            title: 'Atenção!',
            body: 'A soma total dos itens adicionados excede a quantidade máxima que pode ser inserida.',
          });
          setTipoAlert('fail');
          setAbrirAlert(true);
          return;
        }
      }

      const produtoTemporario = {
        id: editandoIndex !== null ? produtosTemporarios[editandoIndex].id : new Date().getTime(),
        produtoId: produtoEstoqueSelecionado.produtoId,
        produto: produtoEstoqueSelecionado.nome,
        quantidade: quantidadeProduto,
        codigoProduto: produtoEstoqueSelecionado.produtoNumero,
        descStatus: 'Temporário',
        ehServico: produtoEstoqueSelecionado.ehServico,
        itemDeEstoque: produtoEstoqueSelecionado.ehServico === true ? false : itemEstoque,
        baseDeTroca: produtoEstoqueSelecionado.ehServico === true ? false : baseDeTroca,
        codItemBaseDeTroca: produtoEstoqueSelecionado.ehServico === true ? null : produtoRemanufaturaSelecionado?.produtoCodigo,
        nomeItemBaseDeTroca: produtoEstoqueSelecionado.ehServico === true ? null : produtoRemanufaturaSelecionado?.produtoNome,
        serialItemBaseDeTroca: produtoEstoqueSelecionado.ehServico === true ? null : serialProdutoRemanufatura,
        motivoProdutoRemanufatura: produtoEstoqueSelecionado.ehServico === true ? null : motivoProdutoRemanufatura,
        limiteQuantidade: produtoEstoqueSelecionado.ehServico === true ? null : produtoEstoqueSelecionado.limiteQuantidade,
        quantidadeDisponivelRegras: produtoEstoqueSelecionado.ehServico === true ? null : produtoEstoqueSelecionado.quantidadeDisponivelRegras
      };

      if (editandoIndex !== null) {
        const updatedProdutos = [...produtosTemporarios];
        updatedProdutos[editandoIndex] = produtoTemporario;
        setProdutosTemporarios(updatedProdutos);
        setEditandoIndex(null);
      } else {
        setProdutosTemporarios([...produtosTemporarios, produtoTemporario]);
      }

      setAbrirDialog(false);
      setProdutoEstoqueSelecionado(null);
      resetarCampos();
    } catch (error) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Falha ao adicionar o produto.',
      });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
  };

  const preparaDadosParaExportacao = async () => {
    let data = [];

    if (itensOS.length > 0) {
      for (let itens of itensOS) {
        let itensObjeto = {
          'Número do Produto': itens.numeroProduto,
          'Produto': itens.produto,
          'Quantidade': itens.quantidade,
          'Status': itens.descStatus
        };
        data.push(itensObjeto);
      }
    }
    setDataExportacao(data);
  };

  const handleSalvarAlteracoes = () => {
    let hasError = false;

    if (!produtoEstoqueSelecionado) {
      setMensagemAlert({ title: 'Atenção!', body: 'Selecione um produto.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      hasError = true;
    }

    if (quantidadeProduto <= 0) {
      setMensagemAlert({ title: 'Atenção!', body: 'Quantidade deve ser maior que 0.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      hasError = true;
    }

    if (
      baseDeTroca &&
      (!produtoRemanufaturaSelecionado || !serialProdutoRemanufatura.trim() || !motivoProdutoRemanufatura.trim())
    ) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Base de troca requer a seleção de um produto, número serial e o motivo.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      hasError = true;
    }

    if (hasError) return;

    if (editandoIndex !== null) {
      aplicarEdicao();
    } else {
      adicionarProdutoTemporario();
    }
  };

  const aplicarEdicao = async () => {
    try {
      if (!produtoEstoqueSelecionado || quantidadeProduto < 1) {
        setMensagemAlert({ title: 'Atenção!', body: 'Por favor, selecione um produto e defina uma quantidade válida.' });
        setTipoAlert('fail');
        setAbrirAlert(true);
        return;
      }

      const index = produtosTemporarios.findIndex((item) => item.id === editandoIndex);
      const produtoExistente = itensOS.find((item) => item.produtoId === produtoEstoqueSelecionado.produtoId && item.id === produtoEstoqueSelecionado.id);
      const produtoServico = estoqueItens.find((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

      if (produtoExistente && itemEditado === 'OS') {
        let quantidadeTotalInserida = parseFloat(quantidadeProduto);

        const produtosInseridos = itensOS.filter((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

        if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === true) {
          if (produtosInseridos) {
            produtosInseridos.forEach(produto => {
              quantidadeTotalInserida += parseFloat(produto.quantidade);
            });
          }
          quantidadeTotalInserida = Math.abs(quantidadeTotalInserida - (produtoExistente?.quantidade || 0));

          if (quantidadeTotalInserida > produtoEstoqueSelecionado.qtdDisponivel) {
            setMensagemAlert({ title: 'Atenção!', body: 'A soma total dos itens adicionados excede a quantidade do produto disponível em estoque.' });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
          }
        } else if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === false) {
          if (produtosInseridos) {
            produtosInseridos.forEach(produto => {
              quantidadeTotalInserida += parseFloat(produto.quantidade);
            });
          }
          quantidadeTotalInserida = Math.abs(quantidadeTotalInserida - (produtoExistente?.quantidade || 0));

          if (produtoServico?.quantidadeDisponivelRegras == true) {
            if (quantidadeTotalInserida > produtoServico.limiteQuantidade) {
              setMensagemAlert({ title: 'Atenção!', body: 'A soma total dos itens adicionados excede a quantidade máxima que pode ser inserida.' });
              setTipoAlert('fail');
              setAbrirAlert(true);
              return;
            }
          }
        }

        setCarregandoEnvioProdutos(true);

        let retorno = await alteraProduto(
          produtoEstoqueSelecionado.produtoId,
          produtoEstoqueSelecionado.produto,
          quantidadeProduto,
          produtoEstoqueSelecionado.produtoNumero,
          produtoEstoqueSelecionado.itemID,
          produtoServico.ehServico,
          produtoEstoqueSelecionado.itemDeEstoque,
          produtoEstoqueSelecionado.baseDeTroca,
          produtoEstoqueSelecionado.codItemBaseDeTroca,
          produtoEstoqueSelecionado.nomeItemBaseDeTroca,
          produtoEstoqueSelecionado.serialItemBaseDeTroca,
          produtoEstoqueSelecionado.motivoProdutoRemanufatura,
        );

        if (retorno === true) {
          setAbrirDialog(false);
          setMensagemAlert({ title: 'Sucesso!', body: 'Produto alterado com sucesso.' });
          setTipoAlert('success');
          setAbrirAlert(true);
          resetarCampos();
          consultaDetalheOS();
          consultaProdutosEstoque();
          setCarregandoEnvioProdutos(false);
        }
        setCarregandoEnvioProdutos(false);
        return;
      }

      if (index !== -1) {
        let quantidadeTotal = parseFloat(quantidadeProduto);

        const produtosExistentes = produtosTemporarios.filter((produto) =>
          produto.produtoId === produtoEstoqueSelecionado.produtoId && produto.id !== produtoEstoqueSelecionado.id
        );

        const produtosInseridos = itensOS.filter((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

        if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === true) {
          if (produtosInseridos) {
            produtosInseridos.forEach(produto => {
              quantidadeTotal += parseFloat(produto.quantidade);
            });
          } else if (produtosExistentes.length > 0) {
            produtosExistentes.forEach(produto => {
              quantidadeTotal += parseFloat(produto.quantidade);
            });
          }

          if (quantidadeTotal > produtoEstoqueSelecionado.qtdDisponivel) {
            setMensagemAlert({ title: 'Atenção!', body: 'A soma total dos itens adicionados excede a quantidade do produto disponível em estoque.' });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
          }
        } else if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === false) {
          if (produtosInseridos) {
            produtosInseridos.forEach(produto => {
              quantidadeTotal += parseFloat(produto.quantidade);
            });
          } else if (produtosExistentes.length > 0) {
            produtosExistentes.forEach(produto => {
              quantidadeTotal += parseFloat(produto.quantidade);
            });
          }

          if (produtoServico?.quantidadeDisponivelRegras == true) {
            if (quantidadeTotal > produtoServico.limiteQuantidade) {
              setMensagemAlert({ title: 'Atenção!', body: 'A soma total dos itens adicionados excede a quantidade máxima que pode ser inserida.' });
              setTipoAlert('fail');
              setAbrirAlert(true);
              return;
            }
          }
        }

        const updatedProdutos = [...produtosTemporarios];
        updatedProdutos[index] = {
          ...updatedProdutos[index],
          id: produtoEstoqueSelecionado.id,
          produtoId: produtoEstoqueSelecionado.produtoId,
          produto: produtoEstoqueSelecionado.nome,
          produtoNumero: produtoEstoqueSelecionado.produtoNumero,
          quantidade: quantidadeProduto,
          itemDeEstoque: produtoEstoqueSelecionado.itemDeEstoque,
          baseDeTroca: produtoEstoqueSelecionado.baseDeTroca,
          codItemBaseDeTroca: produtoEstoqueSelecionado.codItemBaseDeTroca,
          nomeItemBaseDeTroca: produtoEstoqueSelecionado.nomeItemBaseDeTroca,
          serialItemBaseDeTroca: produtoEstoqueSelecionado.serialItemBaseDeTroca,
          motivoProdutoRemanufatura: produtoEstoqueSelecionado.motivoProdutoRemanufatura,
          limiteQuantidade: produtoEstoqueSelecionado.limiteQuantidade,
          quantidadeDisponivelRegras: produtoEstoqueSelecionado.quantidadeDisponivelRegras
        };

        setProdutosTemporarios(updatedProdutos);
        setAbrirDialog(false);
        setMensagemAlert({ title: 'Sucesso!', body: 'Produto alterado com sucesso.' });
        setTipoAlert('success');
        setAbrirAlert(true);
        resetarCampos();
      } else {
        setMensagemAlert({ title: 'Erro!', body: 'Produto não encontrado.' });
        setTipoAlert('error');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao aplicar as alterações no produto.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
  };

  const removerProduto = (id) => {
    try {
      const produto = produtosTemporarios.find((item) => item.id === id);

      if (!produto) {
        setMensagemAlert({ title: 'Atenção!', body: 'Produto não encontrado.' });
        setTipoAlert('fail');
        setAbrirAlert(true);
        return;
      }

      const updatedProdutos = produtosTemporarios.filter((item) => item.id !== id);
      setProdutosTemporarios(updatedProdutos);

      setProdutoEstoqueSelecionado(null);
      setQuantidadeProduto(1);
      setEditandoIndex(null);

      setMensagemAlert({ title: 'Sucesso!', body: `Produto ${produto.produto} removido com sucesso.` });
      setTipoAlert('success');
      setAbrirAlert(true);

    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao remover o produto.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
  };

  const editarProduto = (id, produtoId) => {
    try {
      let produtoTemporario = produtosTemporarios.find((item) => item.id === id);

      setItemEditado('Temporario')

      if (!produtoTemporario) {
        produtoTemporario = estoqueItens.find((item) => item.produtoId === produtoId);

        if (!produtoTemporario) {
          setMensagemAlert({ title: 'Atenção!', body: 'Produto não encontrado.' });
          setTipoAlert('fail');
          setAbrirAlert(true);
          return;
        }

        setItemEditado('OS')
      }

      const produtoQuantidadeDisponivel = estoqueItens.find((item) => item.produtoId === produtoId);

      if (!produtoQuantidadeDisponivel) {
        setMensagemAlert({ title: 'Atenção!', body: 'O produto não está mais disponível. Informe ao seu supervisor.' });
        setTipoAlert('fail');
        setAbrirAlert(true);
        return false;
      }

      const item = itensOS.find((item) => item.produtoId === produtoId && item.id === id);

      setProdutoEstoqueSelecionado({
        id: produtoTemporario?.id || item?.id,
        produtoId: produtoTemporario?.produtoId,
        nome: produtoTemporario?.produto || produtoTemporario?.nome,
        produtoNumero: produtoTemporario?.codigoProduto || produtoTemporario?.numeroProduto || produtoQuantidadeDisponivel?.produtoNumero,
        qtdDisponivel: produtoQuantidadeDisponivel?.qtdDisponivel || produtoQuantidadeDisponivel?.quantidade,
        ehServico: produtoQuantidadeDisponivel?.ehServico,
        itemID: item?.itemID,
        itemDeEstoque: produtoTemporario?.itemDeEstoque || item?.itemDeEstoque,
        baseDeTroca: produtoTemporario?.baseDeTroca || item?.baseDeTroca,
        codItemBaseDeTroca: produtoTemporario?.codItemBaseDeTroca || item?.codItemBaseDeTroca,
        nomeItemBaseDeTroca: produtoTemporario?.nomeItemBaseDeTroca || item?.nomeItemBaseDeTroca,
        serialItemBaseDeTroca: produtoTemporario?.serialItemBaseDeTroca || item?.serialItemBaseDeTroca,
        motivoProdutoRemanufatura: produtoTemporario?.motivoProdutoRemanufatura || item?.motivoProdutoRemanufatura,
        limiteQuantidade: produtoTemporario?.limiteQuantidade,
        quantidadeDisponivelRegras: produtoTemporario?.quantidadeDisponivelRegras
      });

      setQuantidadeProduto(produtoTemporario.quantidade || 1);
      setEditandoIndex(id);
      setAbrirDialog(true);

    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao editar o produto.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
  };

  const resetarCampos = () => {
    setProdutoEstoqueSelecionado(null);
    setQuantidadeProduto(1);
    setEditandoIndex(null);
    setItemEstoque(true);
  };

  const enviarProdutos = async (event) => {
    setCarregandoEnvioProdutos(true);
    event.preventDefault();

    try {
      const promises = produtosTemporarios.map((produto) =>
        addProduto(
          produto.produtoId,
          produto.produto,
          produto.quantidade,
          produto.codigoProduto,
          produto.ehServico,
          produto.itemDeEstoque,
          produto.baseDeTroca,
          produto.codItemBaseDeTroca,
          produto.nomeItemBaseDeTroca,
          produto.serialItemBaseDeTroca,
          produto.motivoProdutoRemanufatura
        )
      );
      const results = await Promise.all(promises);
      const allSuccessful = results.every((result) => result);

      if (allSuccessful) {
        setProdutosTemporarios([]);
        consultaDetalheOS();
        consultaProdutosEstoque();
        setMensagemAlert({ title: 'Sucesso!', body: 'Produtos enviados com sucesso!' });
        setTipoAlert('success');
        setCarregandoEnvioProdutos(false);
      } else {
        setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar alguns produtos.' });
        setTipoAlert('error');
        setCarregandoEnvioProdutos(false);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar os produtos.' });
      setTipoAlert('error');
      setCarregandoEnvioProdutos(false);
    }
  };

  useEffect(() => {
    preparaDadosParaExportacao();
  }, [os]);

  const filtrarProdutos = useMemo(() => {
    return itensOS.concat(produtosTemporarios).filter(
      (item) =>
        item &&
        item.produto &&
        item.produto.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
        item.codigoProduto &&
        item.codigoProduto.toLowerCase().includes(termoPesquisa.toLowerCase())
    );
  }, [termoPesquisa, itensOS, produtosTemporarios]);

  const produtosFiltrados = filtrarProdutos;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = produtosFiltrados.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = useCallback(() => {
    if (currentPage < Math.ceil(produtosFiltrados.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, produtosFiltrados.length, itemsPerPage]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(produtosFiltrados.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [produtosFiltrados]);

  const bloquearEnvio = itensOS.concat(produtosTemporarios).some(
    (item) => item.descStatus === 'Aprovado'
  ) && !itensOS.concat(produtosTemporarios).some(
    (item) => item.produto === 'TARIFA DE DESLOCAMENTO' || item.produto === 'SERVICO DE PEDAGIO'
  );

  const StatusDiv = ({ status }) => {
    let textoStatus = '';
    let statusCor = '';
    let IconeStatus = null;

    switch (status) {
      case 'Aprovado':
        textoStatus = 'Aprovado';
        statusCor = 'rgba(3, 201, 169, 1)';
        IconeStatus = CheckCircleIcon;
        break;
      case 'Aguardando Aprovacao':
        textoStatus = 'Aguardando Aprovação';
        statusCor = '#777';
        IconeStatus = HourglassEmptyIcon;
        break;
      case 'Recusado':
        textoStatus = 'Recusado';
        statusCor = 'rgba(255, 99, 132, 1)';
        IconeStatus = CancelIcon;
        break;
      case 'Temporário':
        textoStatus = 'Temporário';
        statusCor = '#87CEEB';
        IconeStatus = InfoIcon;
        break;
      default:
        textoStatus = 'Desconhecido';
        statusCor = '#000';
        IconeStatus = InfoIcon;
        break;
    }

    return (
      <div style={{
        fontSize: '0.8rem',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 5px',
        color: '#fff',
        borderRadius: '10px',
        textAlign: 'center',
        backgroundColor: statusCor,
        maxWidth: '200px',
        margin: '0 auto',
      }}>
        <IconeStatus style={{ marginRight: '8px', color: '#fff' }} />
        {textoStatus}
      </div>
    );
  };

  const itensAdicionados = currentItems.length > 0;

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={12} sm={3} md={3}>
            <FilesExportFilterProducts
              fileName={`Orçamento da ${na}`}
              data={dataExportacao}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: 10 }}>
            <Grid container>
              <Grid item xs={12} sm={3} md={3}>
                <Tooltip title={temProdutoAprovadoNaoTarifa ? 'Não é possível adicionar itens após o orçamento ter sido aprovado.' : ''}>
                  <span>
                    <BlueButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AddBoxIcon />}
                      onClick={abrirModalAdicionarItens}
                      disabled={temProdutoAprovadoNaoTarifa}
                      style={{ marginBottom: 20 }}
                    >
                      Adicionar Produto
                    </BlueButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={5}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.searchFieldContainer}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Pesquisar Produto"
                    value={termoPesquisa}
                    onChange={(e) => setTermoPesquisa(e.target.value)}
                    className={classes.searchField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginRight: 10 }}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            {!itensAdicionados && (
              <Typography
                variant="body1"
                align="center"
                color="textSecondary"
                style={{ paddingTop: theme.spacing(8), paddingBottom: theme.spacing(8) }}
              >
                Nenhum produto ou serviço adicionado.
              </Typography>
            )}

            {itensAdicionados && (
              <Grid container spacing={1} style={{ marginTop: theme.spacing(3) }} ref={textRef}>
                {currentItems.map((item, index) => (
                  <Grid item xs={12} sm={isMobile ? 12 : 4} md={4} key={`${item.produtoId}-${index}`}>
                    <Paper className={classes.itemContainer}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} style={{marginTop: 5}}>
                          <Tooltip title={item.produto} placement="top" arrow>
                            <Typography component="div" variant="body2" className={classes.truncatedText}>
                              {item.produto}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredText}>
                          <Typography component="div" variant="body2">Código: {item.numeroProduto ? item.numeroProduto : item.codigoProduto} </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredText}>
                          <Typography component="div" variant="body2">Quantidade: {item.quantidade}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredText}>
                          <StatusDiv status={item.descStatus} />
                        </Grid>
                        <Grid item xs={12} className={classes.actionButtons}>
                          <BlueButton
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => editarProduto(item.id, item.produtoId)}
                            disabled={
                              item.descStatus === 'Aprovado'
                            }
                          >
                            Editar
                          </BlueButton>
                          <BlueButton
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() => removerProduto(item.id)}
                            disabled={
                              item.descStatus === 'Aprovado' ||
                              item.descStatus === 'Aguardando Aprovacao' ||
                              item.descStatus === 'Recusado'
                            }
                          >
                            Remover
                          </BlueButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>

      {produtosTemporarios.length > 0 && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={12} sm={3} md={3}>
            <BlueButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={enviarProdutos}
              disabled={bloquearEnvio || carregandoEnvioProdutos}
              style={{ marginTop: theme.spacing(2), height: 55 }}
              startIcon={carregandoEnvioProdutos ? <CircularProgress size={20} /> : <SendIcon />}
            >
              <span style={{ fontSize: '0.7rem' }}>{carregandoEnvioProdutos ? ' Enviando...' : ' Enviar Orçamento'}</span>
            </BlueButton>
          </Grid>
        </Grid>
      )}

      {totalPages > 1 && (
        <Grid container justify="center" style={{ marginTop: theme.spacing(3) }}>
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            <ArrowBackIcon />
          </Button>
          <Typography variant="body2" style={{ padding: '10px 16px' }}>
            Página {currentPage} de {totalPages}
          </Typography>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <ArrowForwardIcon />
          </Button>
        </Grid>
      )}

      <Dialog
        open={abrirDialog}
        aria-labelledby="customized-dialog-title"
        maxWidth={isMobile ? false : 'sm'}
        classes={{ paper: isMobile ? classes.dialogPaperMobile : '' }}
        style={{ marginTop: 35, marginBottom: 10 }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setAbrirDialog(false);
            resetarCampos();
          }
        }}
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              {editandoIndex !== null ? 'Editar Produto' : 'Adicionar Produto'}
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton
                onClick={() => {
                  resetarCampos();
                  setAbrirDialog(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <div style={{ marginBottom: '16px' }}>
            <Autocomplete
              id="produto-select"
              options={os.tipoServicoAgendamento === 'INSTALACAO' ? estoqueItens.filter((item) => item.nome !== 'TARIFA DE DESLOCAMENTO') : estoqueItens}
              getOptionLabel={(option) => `${option.produtoNumero} - ${option.nome}`}
              value={produtoEstoqueSelecionado}
              disabled={editandoIndex !== null && itensOS?.find((item) => item.produtoId === produtoEstoqueSelecionado?.produtoId)}
              onChange={(event, newValue) => {
                setProdutoEstoqueSelecionado(newValue);
                setQuantidadeProduto(1);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      Selecionar Produto/Serviço <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <TextField
              variant="outlined"
              fullWidth
              label={
                <>
                  Quantidade
                  <span style={{ color: 'red' }}> *</span>
                </>
              }
              type="number"
              value={quantidadeProduto}
              onChange={(event) => setQuantidadeProduto(event.target.value)}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true
                    ? produtoEstoqueSelecionado?.qtdDisponivel
                    : produtoEstoqueSelecionado?.quantidadeDisponivelRegras == true
                      ? produtoEstoqueSelecionado?.limiteQuantidade
                      : 100000,
                },
                endAdornment: (
                  <span style={{ marginLeft: 5 }}>
                    {produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true
                      ? `/${produtoEstoqueSelecionado?.qtdDisponivel}`
                      : produtoEstoqueSelecionado?.quantidadeDisponivelRegras == true
                        ? `/${produtoEstoqueSelecionado?.limiteQuantidade}`
                        : ''}
                  </span>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {produtoEstoqueSelecionado?.ehServico === false && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={produtoEstoqueSelecionado?.ehServico === true}
                    checked={editandoIndex !== null ? produtoEstoqueSelecionado?.itemDeEstoque : itemEstoque}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      if (editandoIndex !== null) {
                        setProdutoEstoqueSelecionado((prevProduto) => ({
                          ...prevProduto,
                          itemDeEstoque: isChecked,
                        }));
                      } else {
                        setItemEstoque(isChecked);
                      }
                    }}
                    color="primary"
                  />
                }
                label="Item do meu estoque"
              />
              <div style={{ marginBottom: '16px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={produtoEstoqueSelecionado?.ehServico === true || produtoEstoqueSelecionado?.logisticaReversa === false || editandoIndex !== null}
                      checked={editandoIndex !== null ? produtoEstoqueSelecionado?.baseDeTroca : baseDeTroca}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        if (editandoIndex !== null) {
                          setProdutoEstoqueSelecionado((prevProduto) => ({
                            ...prevProduto,
                            baseDeTroca: isChecked,
                          }));
                        } else {
                          setBaseDeTroca(isChecked);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label="Base de troca"
                />
              </div>
            </>
          )}
          {(
            (produtoEstoqueSelecionado?.ehServico === false && (produtoEstoqueSelecionado?.logisticaReversa === true || produtoEstoqueSelecionado?.logisticaReversa == null) && produtoEstoqueSelecionado?.baseDeTroca === true) ||
            (produtoEstoqueSelecionado?.ehServico === false && (produtoEstoqueSelecionado?.logisticaReversa === true || produtoEstoqueSelecionado?.logisticaReversa == null) && baseDeTroca === true)
          ) && (
              <div style={{ marginBottom: '16px' }}>
                <Typography component="div" variant="body2">
                  Informar abaixo o modelo e serial do equipamento retirado do veículo do cliente e o motivo.
                </Typography>

                <div style={{
                  display: 'flex',
                  gap: '16px',
                  marginTop: '16px',
                  flexDirection: 'column'
                }}>

                  <Autocomplete
                    id="produto-select"
                    options={produtosRemanufatura}
                    getOptionLabel={(option) => `${option.produtoCodigo} - ${option.produtoNome}`}
                    value={editandoIndex !== null
                      ? produtosRemanufatura?.find((option) => option.produtoCodigo === produtoEstoqueSelecionado?.codItemBaseDeTroca)
                      : produtoRemanufaturaSelecionado}
                    disabled={produtoEstoqueSelecionado?.ehServico === true || produtoEstoqueSelecionado?.logisticaReversa === false || editandoIndex !== null}
                    onChange={(event, newValue) => {
                      if (editandoIndex !== null) {
                        setProdutoEstoqueSelecionado((prevProduto) => ({
                          ...prevProduto,
                          codItemBaseDeTroca: newValue?.produtoCodigo || null,
                          nomeItemBaseDeTroca: newValue?.produtoNome || null,
                        }));
                      } else {
                        setProdutoRemanufaturaSelecionado(newValue);
                        setErroProdutoRemanufatura(false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Selecionar Produto <span style={{ color: 'red' }}> *</span>
                          </span>
                        }
                        variant="outlined"
                        error={erroProdutoRemanufatura}
                        helperText={erroProdutoRemanufatura ? 'Seleção obrigatória.' : ''}
                      />
                    )}
                  />

                  <TextField
                    variant="outlined"
                    label={
                      <>
                        Número Serial <span style={{ color: 'red' }}> *</span>
                      </>
                    }
                    type="text"
                    disabled={produtoEstoqueSelecionado?.ehServico === true || produtoEstoqueSelecionado?.logisticaReversa === false || editandoIndex !== null}
                    value={editandoIndex !== null
                      ? produtoEstoqueSelecionado?.serialItemBaseDeTroca
                      : serialProdutoRemanufatura}
                    onChange={(event) => {
                      setSerialProdutoRemanufatura(event.target.value);
                      setErroSerial(false);
                    }}
                    error={erroSerial}
                    helperText={erroSerial ? 'Campo obrigatório.' : ''}
                  />
                  <Autocomplete
                    id="motivo-select"
                    options={motivosDesconto}
                    getOptionLabel={(option) => option?.label ?? ''}
                    value={motivosDesconto.find((item) => item.id === motivoProdutoRemanufatura) || null}
                    disabled={produtoEstoqueSelecionado?.ehServico === true || produtoEstoqueSelecionado?.logisticaReversa === false || editandoIndex !== null}
                    onChange={(event, newValue) => {
                      setMotivoProdutoRemanufatura(newValue ? newValue.id : null);
                      setErroMotivo(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Selecionar Motivo <span style={{ color: 'red' }}> *</span>
                          </span>
                        }
                        variant="outlined"
                        error={erroMotivo}
                        helperText={erroMotivo ? 'Seleção obrigatória.' : ''}
                      />
                    )}
                  />
                </div>
              </div>
            )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            onClick={() => {
              resetarCampos();
              setAbrirDialog(false);
            }}
            variant="contained"
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>Voltar</span>
          </Button>
          <BlueButton
            onClick={handleSalvarAlteracoes}
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            disabled={!produtoEstoqueSelecionado || quantidadeProduto < 1 || carregandoEnvioProdutos}
            startIcon={
              carregandoEnvioProdutos ? <CircularProgress size={20} /> : ''
            }
          >
            <span style={{ fontSize: '0.7rem' }}>
              {carregandoEnvioProdutos
                ? ' Salvando...'
                : (editandoIndex !== null ? 'Salvar Alterações' : 'Adicionar')}
            </span>
          </BlueButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
