import React, { useEffect, useState, useCallback } from 'react';
import { CircularProgress, Box, Paper, makeStyles, Grid, TextField, InputAdornment, IconButton, useMediaQuery, useTheme, Typography } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PhoneIcon from '@material-ui/icons/Phone';
import noticiasService from '../Services/Noticias/noticia-service';
import authService from '../Services/auth/auth-service';
import CustomTablePagination from '../components/pagination/pagination-component';
import debounce from 'lodash.debounce';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 20px 15px',
		borderRadius: 10,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px'
		}
	},
	progress: {
		color: '#209BDE',
	},
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px'
	},
	mensagemStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	}
}));

export default function NoticiaPage() {
	const classes = useStyles();
	const [listanoticias, setNoticias] = useState([]);
	const [loading, setLoading] = useState(false);
	const [temPermissao, setTemPermissao] = useState(true);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [pesquisa, setPesquisa] = useState('');
	const [totalDados, setTotalDados] = useState(0);
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		const usuarioInfo = authService.getUserInfo().usuario;
		const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
		const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
		const empresa = usuarioInfo.empresa;
		const tecnico = usuarioInfo.tecnico;
		let temPermissao = true;

		if (empresa || tecnico) {
			temPermissao = permissaoAgendaGlobal?.some(permissao =>
				permissao.permissoesLista?.some(permissaoItem =>
					permissaoItem.trim() === 'cod_noticias'
				)
			);
		} else {
			temPermissao = true;
		}

		if (!temPermissao) {
			setTemPermissao(false);
		} else {
			setTemPermissao(true);
		}
		if (tecnicoPrestador && !temPermissao) {
			return;
		}

	}, []);

	useEffect(() => {
		noticias(page, pageSize, pesquisa);
	}, []);

	const noticias = async (page, pageSize, pesquisa) => {
		setLoading(true);
		try {
			const dados = {
				pagina: page ? page : 0,
				limite: pageSize ? pageSize : 8,
				pesquisa: pesquisa ? pesquisa : ''
			};

			let noticiasData = await noticiasService.noticias(dados);
			if (!noticiasData.error) {
				let _noticias = noticiasData.dados.map((m) => ({
					id: m.id || '',
					txtTitulo: m.titulo || '',
					corpo: m.corpo || ''
				}));

				setTotalDados(noticiasData.total)
				setNoticias(_noticias);
			} else {
				setTotalDados(0);
				setNoticias([]);
			}
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const mudarPagina = async (event, newPage) => {
		setPage(newPage);
		await noticias(newPage, pageSize, pesquisa)
	};

	const mudarTamanhoPagina = async (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
		await noticias(0, parseInt(event.target.value, 10), pesquisa)
	};

	const limparPesquisa = async () => {
		setPesquisa('');
		await noticias(page, pageSize, '');
	};

	const delayPesquisa = useCallback(
		debounce((nome) => {
			noticias(0, pageSize, nome);
		}, 700), []
	);

	const valorPesquisa = (nome) => {
		setPesquisa(nome);
		if (nome !== pesquisa) {
			setPesquisa(nome);
			delayPesquisa(nome);
		}
	};

	return (
		<>
			{temPermissao ? (
				<div>
					<MenuComponent />
					<div style={{ margin: 30 }}>
						<h3 className={classes.tituloStyle}>Notícias</h3>
						<h4 className={classes.subtituloStyle}>
							<span
								onClick={() => {
									const userInfo = authService.getUserInfo();
									if (userInfo.usuario.gestor) {
										window.location.assign('/gestor');
									} else {
										window.location.assign('/revenda');
									}
								}}
								className={classes.linkStyle}
								style={{ cursor: 'pointer' }}
							>
								Home
							</span> {'>'} Notícias
						</h4>

						<Grid container justifyContent="flex-end" style={{ marginBottom: 20 }}>
							<Grid item xs={isMobile ? 12 : 5}>
								<TextField
									label="Pesquisar"
									variant="outlined"
									fullWidth
									value={pesquisa}
									onChange={(event) => valorPesquisa(event.target.value)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{pesquisa ? (
													<IconButton
														aria-label="Limpar Pesquisa"
														onClick={limparPesquisa}
														edge="end"
													>
														<ClearIcon />
													</IconButton>
												) : (
													<SearchIcon />
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>

						<Paper className={classes.paper}>
							{loading ? (
								<Grid container alignItems="center" justifyContent="center">
									<CircularProgress className={classes.progress} />
								</Grid>
							) : (
								<List>
									{listanoticias.length > 0 ? (
										listanoticias.map((m) => (
											<ListItem key={m.id}>
												<ListItemAvatar>
													<Avatar>
														<NewReleasesIcon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={m.txtTitulo}
													secondary={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>{m.corpo}</div>}
												/>
											</ListItem>
										))
									) : (
										<Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
											<Typography variant="subtitle1" color="textSecondary">
												<p>Não há registros para serem exibidos.</p>
											</Typography>
										</Grid>
									)}
								</List>
							)}

							{listanoticias.length > 0 && (
								<Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
									<Grid item>
										<CustomTablePagination
											onPageChange={mudarPagina}
											page={page}
											count={totalDados}
											quantidadeItens={totalDados}
											rowsPerPage={pageSize}
											onRowsPerPageChange={mudarTamanhoPagina}
										/>
									</Grid>
								</Grid>
							)}
						</Paper>

						<Box>
							<Box textAlign="center" fontWeight={800} m={1}>
								Fale com a Omnilink
							</Box>

							<Box fontSize={16} justifyContent="center" display="flex">
								<Box>
									<PhoneIcon />
								</Box>
								<Box>0800-888-6200</Box>
							</Box>
						</Box>
					</div>
				</div>
			) : (
				<>
					<MenuComponent />
					<div style={{ margin: 30 }}>
						<h3 className={classes.tituloStyle}>Notícias</h3>
						<h4 className={classes.subtituloStyle}>
							<span
								onClick={() => (window.location.href = '/agenda')}
								className={classes.linkStyle}
								style={{ cursor: 'pointer' }}
							>
								Home
							</span> {'>'} Notícias
						</h4>

						<Paper className={classes.paper}>
							<Grid container justifyContent="center">
								<h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
									Você não tem permissão para acessar esta página!
								</h2>
							</Grid>
						</Paper>
					</div>
				</>
			)}
		</>
	);
}
